import { getStorageData, saveStorageData } from '../localStorage/localStorage';
import { PAGE_REFERRER_STORAGE_KEY } from './referrerTrackerConstants';
import { SESSION_STORAGE } from '../localStorage/localStorageConstants';

export const getPageReferrerUrl = () => getStorageData(PAGE_REFERRER_STORAGE_KEY, SESSION_STORAGE) || { url: '' };

export const getProductReferrer = listingCategoryId => {
  const productReferrer = getStorageData(`referrerProduct/${listingCategoryId}`, SESSION_STORAGE);
  const pageReferrerUrl = getPageReferrerUrl().url;
  if (pageReferrerUrl && productReferrer && productReferrer.url === pageReferrerUrl) {
    return productReferrer;
  }
  return null;
};

export const saveProductReferrer = (listingCategoryId, product, isGridView) => {
  saveStorageData(
    `referrerProduct/${listingCategoryId}`,
    {
      page: product.page,
      productId: product.id,
      url: product.url,
      isGridView,
    },
    SESSION_STORAGE
  );
};
