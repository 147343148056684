import { connect } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Label from 'app/commonComponents/atoms/label';
import Field from 'app/commonComponents/atoms/input';

const Checkbox = ({
  checkboxName,
  checkboxClass,
  checkboxLabelClass,
  checkboxInputClass,
  checkboxLabel,
  checkboxLabelId,
  checkboxOnChange,
  formik: { handleChange, values, errors },
  ...props
}) => (
  <Label htmlFor={checkboxName} className={checkboxClass || ''}>
    <span className="form-checkbox">
      <Field
        type="checkbox"
        name={checkboxName}
        id={checkboxName}
        className={`form-checkbox__input ${checkboxInputClass || ''}`}
        checked={values[checkboxName] || false}
        onChange={checkboxOnChange || handleChange}
        {...props}
      />
      <span className={`form-checkbox__checkbox ${errors[checkboxName] ? 'form-checkbox__highlight-error' : ''}`} />
    </span>
    <span className={`form-checkbox__label ${checkboxLabelClass || ''}`}>
      {checkboxLabel || <FormattedMessage id={checkboxLabelId} />}
    </span>
  </Label>
);

Checkbox.defaultProps = {
  checkboxClass: '',
  checkboxLabelClass: '',
  checkboxLabelId: '',
  checkboxLabel: '',
  checkboxInputClass: '',
  checkboxOnChange: null,
};

Checkbox.propTypes = {
  checkboxName: PropTypes.string.isRequired,
  checkboxClass: PropTypes.string,
  checkboxLabelClass: PropTypes.string,
  checkboxLabelId: PropTypes.string,
  checkboxLabel: PropTypes.string,
  checkboxInputClass: PropTypes.string,
  checkboxOnChange: PropTypes.func,
  formik: PropTypes.object.isRequired,
};

export default connect(Checkbox);
