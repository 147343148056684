import { isEmpty, findIndex, filter } from 'lodash';
import { buildSwatchMediaURL } from 'app/utils/imageServerUtils';
import { CHARM, STICKER } from './productDetailsConstants';

const { classificationGroupId } = window.inlineGlobalConfig;

export const findColorSwatches = classificationGroups => {
  const productGroup = classificationGroups.find(
    classificationGroup => classificationGroup.id === classificationGroupId
  );
  return productGroup && productGroup.classificationGroup ? productGroup.classificationGroup : [];
};

export const getActualSwatch = (colorSwatches, actualColorId) =>
  !isEmpty(colorSwatches) || actualColorId ? [...colorSwatches].find(swatch => actualColorId === swatch.id) : {};

export const buildMediaURL = colorSwatches =>
  colorSwatches.map(swatch => ({
    ...swatch,
    mediaURL: swatch.swatchUrl ? swatch.swatchUrl : buildSwatchMediaURL(swatch.mediaCode),
  }));

export const getSizeData = data => {
  let sizeVariationIndex = -1;

  if (data.variationFields) {
    sizeVariationIndex = findIndex(data.variationFields, field => field.name === 'size');
  }

  return sizeVariationIndex >= 0
    ? data.skus.map(size => ({
        sku: size.id,
        name: size.variationValues[sizeVariationIndex].name,
        size: size.variationValues[sizeVariationIndex].value,
        dataLayerName: size.variationValues[sizeVariationIndex].dataLayerName,
        count: size.stock,
        hybrisSizeCode: size.variationValues[sizeVariationIndex].id,
      }))
    : [];
};

export const isFullPrice = data => {
  if (!data) return false;
  const { classificationData } = data;
  const { value: clearanceStatus } = classificationData.entry.find(element => element.key === 'clearanceStatus');
  return clearanceStatus === 'Full Price' || clearanceStatus === '';
};

export const isColourVariantFullPrice = data => {
  if (!data) return false;
  const { clearanceStatus } = data;
  return clearanceStatus === 'N';
};

export const getStyleId = loadedProduct =>
  loadedProduct.classificationData.entry.find(e => e.key === 'styleCode').value;

export const getColorId = loadedProduct =>
  loadedProduct.classificationData.entry.find(e => e.key === 'colorCode').value;

export const isMultiVariantProduct = productType => productType !== CHARM && productType !== STICKER;

export const getClassificationProperty = (classificationData, propertyName) => {
  return (
    classificationData &&
    !isEmpty(classificationData.entry) &&
    filter(classificationData.entry, dataObject => dataObject.key === propertyName)[0].value
  );
};

export const getClassificationPropertyDetailed = (classifications, propertyName) => {
  return classifications[0]?.features?.find(f => f.featureCode === propertyName)?.featureValues[0];
};
