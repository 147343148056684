export function dlTrackFormFields({ formName, formField }) {
  try {
    window.dataLayer.push({
      event: 'visitor interaction',
      interaction_name: 'form tracking',
      form_name: formName.replace(/([A-Z])/g, ' $1').toLowerCase(),
      form_field: formField
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .replace('username', 'email')
        .replace('line', 'address'),
    });
  } catch (error) {
    console.error(error);
  }
}

export function dlTrackFormSubmit({ errorMsgs, formName }) {
  try {
    const hasErrors = Object.keys(errorMsgs).length;
    const errorFieldName = Object.keys(errorMsgs)[0];
    const formSubmitAnalytics = {
      event: 'visitor interaction',
      interaction_name: hasErrors ? 'form error' : 'form submit',
      [hasErrors ? 'form_error_name' : 'form_name']: formName.replace(/([A-Z])/g, ' $1').toLowerCase(),
    };
    if (hasErrors) {
      formSubmitAnalytics.form_error_field = errorFieldName
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
        .replace('username', 'email')
        .replace('line', 'address');
      formSubmitAnalytics.form_error_message = errorMsgs[errorFieldName]
        ?.replace(/([A-Z])/g, '$1')
        .toLowerCase()
        .trim();
    }
    window.dataLayer.push(formSubmitAnalytics);
  } catch (e) {
    console.error(e);
  }
}

export function dlTrackPoBoxValidationError() {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'PO box or exchange address validation failure',
  });
}
