/*eslint-disable */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ajaxRequest } from 'app/utils/helpers';
import { getStorageData, saveStorageData, removeStorageData } from 'app/utils/localStorage/localStorage';

const { messagesUrl, locale } = window.inlineGlobalConfig;

let globalTranslations = null;

const ProviderHOC =
  (store = null, props = {}) =>
  WrappedComponent =>
    class ProviderWrapper extends Component {
      constructor() {
        super();

        this.state = {
          translations: null,
        };

        this.setTranslations = this.setTranslations.bind(this);
        this.setMessageProperties = this.setMessageProperties.bind(this);
        this.handleLocalisationFailure = this.handleLocalisationFailure.bind(this);
        this.handleLocalisationSuccess = this.handleLocalisationSuccess.bind(this);
      }

      componentDidMount() {
        this.setMessageProperties();
      }

      get localStoragePrefix() {
        const { language, country } = locale;
        return `localisationMessages_${language}-${country}`;
      }

      get localStorageKey() {
        return `${this.localStoragePrefix}_${messagesUrl}`;
      }

      setTranslations(translations) {
        this.setState({
          translations,
        });
        globalTranslations = translations;
      }

      setMessageProperties() {
        const translations = getStorageData(this.localStorageKey);

        if (translations) {
          this.setTranslations(translations);
        } else if (window.hasTranslationsBeenRequested) {
          window.translationPromise.then(response => this.setTranslations(response));
        } else {
          window.translationPromise = ajaxRequest('GET', messagesUrl)
            .then(response => this.handleLocalisationSuccess(response))
            .catch(this.handleLocalisationFailure);
          window.hasTranslationsBeenRequested = true;
        }
      }

      removeLocalisationMessages() {
        Object.keys(localStorage).forEach(key => key.includes(this.localStoragePrefix) && removeStorageData(key));
      }

      handleLocalisationFailure() {
        console.error('No localisable messages');

        this.setTranslations();
      }

      handleLocalisationSuccess(translations) {
        this.removeLocalisationMessages();

        saveStorageData(this.localStorageKey, translations);
        this.setTranslations(translations);

        return translations;
      }

      render() {
        const { translations } = this.state;

        if (!translations) {
          return null;
        }

        if (!store) {
          return (
            <IntlProvider locale={locale.language} messages={translations}>
              <WrappedComponent {...props} />
            </IntlProvider>
          );
        }

        return (
          <IntlProvider locale={locale.language} messages={translations}>
            <Provider store={store}>
              <WrappedComponent {...props} />
            </Provider>
          </IntlProvider>
        );
      }
    };

export const getTranslations = () => Object.assign({}, globalTranslations);
export default ProviderHOC;
