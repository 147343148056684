import { flashSuccessMessage, flashWarningMessage, flashErrorMessage } from 'app/globalMessages/globalMessagesClient';

export const redirectTo = url => {
  window.location.replace(url);
};

export function redirectWithSuccess(url, message) {
  flashSuccessMessage(message);
  redirectTo(url);
}

export function redirectWithWarning(url, message) {
  flashWarningMessage(message);
  redirectTo(url);
}

export function redirectWithError(url, message) {
  flashErrorMessage(message);
  redirectTo(url);
}
