import { uniq } from 'lodash';
import { PAYMENT_METHOD, CARD_ICON_MAPPER } from './checkoutPaymentConstants';

export function getCardBrands(supportedCarts) {
  const brandArray = supportedCarts
    .filter(paymentMode => paymentMode.paymentMethod === PAYMENT_METHOD.CARD)
    .map(card => card.cardBrands)
    .flat();

  return uniq(brandArray);
}

export function getCardIcons(brands) {
  return brands.map(brand => CARD_ICON_MAPPER[brand]);
}

export function isCardPaymentMethodEnabled(paymentMethods) {
  return paymentMethods.some(method => method.paymentMethod === PAYMENT_METHOD.CARD);
}

export function isKlarnaPaymentEnabledForAddress(supportedPaymentModes, selectedDeliveryAddress) {
  return (
    supportedPaymentModes.find(paymentMode => paymentMode.paymentMethod === PAYMENT_METHOD.KLARNA) &&
    window.inlineCheckout.klarnaEnabledCountries.split(', ').includes(selectedDeliveryAddress.country)
  );
}

export function isPaypalPaymentEnabled(paymentMethods) {
  return paymentMethods.some(method => method.paymentMethod === PAYMENT_METHOD.PAYPAL);
}

export function isAfterpayPaymentEnabled(paymentMethods) {
  return paymentMethods.some(method => method.paymentMethod === PAYMENT_METHOD.AFTERPAY);
}

export function getCardIconsFromPaymentMethods(paymentMethods) {
  const cardBrands = getCardBrands(paymentMethods);
  return getCardIcons(cardBrands);
}
