import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { get } from 'lodash';
import { dlTrackFormSubmit } from 'app/analytics/formsAnalytics';

const FormContext = createContext(null);

export const formConnect = WrappedComponent => props =>
  (
    <FormContext.Consumer>
      {formContext => <WrappedComponent formContext={formContext} {...props} />}
    </FormContext.Consumer>
  );

export default function formsHOC({ mapPropsToValues, validate, validations, formName, requiredFields }) {
  return WrappedComponent => {
    const formikHOC = withFormik({
      mapPropsToValues,
      validate,
      handleSubmit() {},
    });

    class FormikWrapper extends Component {
      constructor() {
        super();

        this.submitForm = this.submitForm.bind(this);
      }

      submitForm() {
        const { submitForm } = this.props;
        return submitForm().then(() => {
          const { errors, values } = this.props;
          dlTrackFormSubmit({
            errorMsgs: getErrorMsgsInEnglish(validations, errors),
            formName,
          });
          return { errors, values };
        });
      }

      render() {
        return (
          <FormContext.Provider
            value={{
              formName,
              requiredFields,
              validations,
            }}
          >
            <WrappedComponent {...this.props} formName={formName} submitForm={this.submitForm} />
          </FormContext.Provider>
        );
      }
    }

    FormikWrapper.propTypes = {
      submitForm: PropTypes.func.isRequired,
      errors: PropTypes.objectOf(PropTypes.string).isRequired,
      values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])).isRequired,
    };

    return formikHOC(FormikWrapper);
  };
}

export function getErrorMsgsInEnglish(validation, errors) {
  const { messages, messagesEn } = validation;
  const enErrors = {};
  Object.keys(errors).forEach(key => {
    enErrors[key] = get(messagesEn, getPathOfValue(messages, errors[key]));
  });
  return enErrors;
}

function getPathOfValue(obj, value) {
  let isPathFound = false;

  function findPath(innerObj, innerValue) {
    return Object.keys(innerObj).reduce((acc, currentKey) => {
      let pathOfValue = '';
      if (!isPathFound) {
        if (innerObj[currentKey] === innerValue) {
          pathOfValue = currentKey;
          isPathFound = true;
        } else if (typeof innerObj[currentKey] === 'object') {
          const currentPath = findPath(innerObj[currentKey], innerValue);
          pathOfValue = currentPath ? `${currentKey}.${currentPath}` : '';
        }
      }
      return acc && pathOfValue ? `${acc}.${pathOfValue}` : pathOfValue || acc;
    }, '');
  }

  return findPath(obj, value);
}
