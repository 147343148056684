export function getStorageData(key, storageType = 'localStorage') {
  try {
    const serializedState = window[storageType].getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('loadStorageData', err);
    return undefined;
  }
}

export function saveStorageData(key, val, storageType = 'localStorage') {
  try {
    const serializedState = JSON.stringify(val);
    window[storageType].setItem(key, serializedState);
  } catch (err) {
    console.error('saveStorageData', err);
  }
}

export function removeStorageData(key, storageType = 'localStorage') {
  try {
    window[storageType].removeItem(key);
  } catch (err) {
    console.error('removeStorageData', err);
  }
}

export function popStorageData(key, storageType = 'localStorage') {
  try {
    const serializedState = window[storageType].getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    const data = JSON.parse(serializedState);
    window[storageType].removeItem(key);
    return data;
  } catch (err) {
    console.error('loadStorageData', err);
    return undefined;
  }
}
