export const USER_STATE = 'USER_STATE';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_CHECKOUT_CART_DATA = 'SET_CHECKOUT_CART_DATA';

export const AUTH_URL = '/auth';

export const LOGIN_URL = '/auth/login';

export const FORGOT_PASSWORD_URL = '/forgotPassword';
export const FORGOT_PASSWORD_CONFIRMATION_URL = '/passwordConfirmation';

export const GUEST_URL = '/auth/guest';

export const DELIVERY_URL = '/delivery';
export const DELIVERY_HOME_ADDRESS_URL = `${DELIVERY_URL}/home-delivery-details`;
export const DELIVERY_DPD_URL = `${DELIVERY_URL}/dpd`;
export const DPD_STEP_1 = 'find-collection-point';
export const DPD_STEP_2 = 'choose-collection-point';
export const DPD_STEP_3 = 'collection-details';
export const DELIVERY_DPD_STEP = `${DELIVERY_DPD_URL}/:step`;
export const DELIVERY_DPD_FIND_URL = `${DELIVERY_DPD_URL}/${DPD_STEP_1}`;
export const DELIVERY_DPD_CHOOSE_URL = `${DELIVERY_DPD_URL}/${DPD_STEP_2}`;
export const DELIVERY_DPD_DETAILS_URL = `${DELIVERY_DPD_URL}/${DPD_STEP_3}`;

export const BILLING_URL = '/billing';
export const CARD_URL = '/billing/card';
export const PAYPAL_URL = '/billing/paypal';
export const AFTERPAY_URL = '/billing/afterpay';
export const KLARNA_URL = '/billing/klarna';

export const REVIEW_URL = '/review';

export const SCROLL_TO_ADDRESS_FORM_DURATION = 500;

export const CHECKOUT_APP = 'CHECKOUT_APP';

export const PREDEFINED_ADDRESS = 'PredefinedAddressZoneDeliveryModeModel';

export const REMOVED_COUPON = 'REMOVED_COUPON';

export const SET_CART_PAYMENT_INFO = 'SET_CART_PAYMENT_INFO';

export const SET_CART_DELIVERY_ADDRESS = 'SET_CART_DELIVERY_ADDRESS';

export const KLARNA_SDK_URL = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
