import { decodeHtml } from 'app/utils/helpers';
import { getProductData, reduceProductFields } from './gtmAnalyticsUtils';
import { DL_ITEM_LIST_YMAL } from './gtmAnalyticsConstants';
import { dlSelectItem, dlViewItemList, getBasicListProps } from './productListAnalytics';

const { locale, pageType } = window.inlineGlobalConfig;
const listName = `${pageType} ${DL_ITEM_LIST_YMAL}`;

function dlViewProductCarouselItemList(products) {
  dlViewItemList(products, getBasicListProps(listName));
}

function dlSelectProductCarouselItem(product, index) {
  dlSelectItem(product, {
    ...getBasicListProps(listName),
    index,
  });
}

export function mountAnalytics() {
  const PRODUCTS_DATA_CONTAINER_ID = '#products-data';
  try {
    const products = getProductsDataFromPage(PRODUCTS_DATA_CONTAINER_ID);
    if (products.length > 0) {
      dlViewProductCarouselItemList(products);
      const productElementsNew = document.querySelectorAll(`.carousel-cell > a`);
      productElementsNew.forEach((element, i) => {
        element.addEventListener('click', () => {
          dlSelectProductCarouselItem(products[i], i);
        });
      });

      dlTrackProductImpressions(products, DL_ITEM_LIST_YMAL);
      const viewportSizes = ['small-only', 'medium-up'];
      let productElements;
      viewportSizes.forEach(size => {
        productElements = document.querySelectorAll(`.show-for-${size} .carousel-cell > a`);
        productElements.forEach((element, i) => {
          element.addEventListener('click', () => {
            dlTrackProductSelect({
              product: products[i],
              position: i,
              title: DL_ITEM_LIST_YMAL,
            });
          });
        });
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function getProductsDataFromPage(elementId) {
  const dataHolderElement = document.querySelector(elementId);
  if (!dataHolderElement) {
    return [];
  }

  const products = JSON.parse(dataHolderElement.dataset.products);
  return products.map(productDataAdapter);
}

function productDataAdapter(product) {
  return {
    ...product,
    productCode: product.id,
    name: decodeHtml(product.name.replace(/&amp;/g, '&')) || '',
    price: `${product.price.symbol}${Number.parseFloat(product.price.value).toFixed(2)}`,
    wasPrice: product.wasPrice
      ? `${product.wasPrice.symbol}${Number.parseFloat(product.wasPrice.value).toFixed(2)}`
      : '',
    markdown: product.wasPrice && product.wasPrice.value - product.price.value > 0,
  };
}

function dlTrackProductImpressions(products, title) {
  const productsAnalytics = products.map((product, position) => ({
    ...getProductData(product),
    dimension14: title,
    position,
    list: `${pageType} - ${title}`,
  }));

  try {
    window.dataLayer.push({
      event: 'visitor interaction',
      interaction_name: 'product impression',
      ecommerce: {
        currencyCode: locale.currentCurrency,
        impressions: reduceProductFields(productsAnalytics),
      },
    });
  } catch (error) {
    console.error(error);
  }
}

function dlTrackProductSelect({ product, position, title }) {
  try {
    window.dataLayer.push({
      event: 'visitor interaction',
      interaction_name: 'select product',
      ecommerce: {
        currencyCode: locale.currentCurrency,
        click: {
          products: [
            {
              ...getProductData(product),
              dimension14: title,
              position,
              list: `${pageType} - ${title}`,
            },
          ],
        },
      },
    });
  } catch (error) {
    console.error(error);
  }
}
