const {
  freeDeliveryVisible,
  threshold,
  msgBelowThreshold,
  msgAboveThreshold,
  backgroundBelowThreshold,
  backgroundAboveThreshold,
  displaySeconds,
} = window.inlineFreeDeliveryThreshold || {};

export const FREE_DELIVERY_VISIBLE = freeDeliveryVisible;
export const THRESHOLD = threshold;
export const ABOVE_THRESHOLD_MSG = msgAboveThreshold;
export const BELOW_THRESHOLD_BG = backgroundBelowThreshold || '#228B22';
export const ABOVE_THRESHOLD_BG = backgroundAboveThreshold || '#228B22';
export const DISPLAY_SECONDS = parseInt(displaySeconds, 10) || 5;

const CURRENCY_SYMBOL = {
  GBP: '£',
  CAD: 'C$',
  EUR: '€',
  USD: '$',
};

export const getZeroPrice = ccy => {
  switch (ccy) {
    case ccy:
      return `${CURRENCY_SYMBOL[ccy]}0.00`;

    default:
      return `0.00`;
  }
};

const PLACEHOLDER = '[CURRENT_DIFFERENCE] ';
const CODE_LENGTH = 3;

export const getBelowThresholdMsg = subTotal => {
  const placeholderLength = PLACEHOLDER.length;
  const startIndex = msgBelowThreshold.indexOf(PLACEHOLDER) + placeholderLength;
  const endIndex = startIndex + CODE_LENGTH;
  const currency = msgBelowThreshold.slice(startIndex, endIndex);

  return msgBelowThreshold.replace(
    `${PLACEHOLDER}${currency}`,
    `${CURRENCY_SYMBOL[currency]}${(threshold - subTotal).toFixed(2)}`
  );
};
