import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { webpQuality, enableWebpQuality, mediaQueries } = window.inlineGlobalConfig;

const getWebpQuality = width => {
  let prevValue = 0;
  let quality = Object.values(webpQuality).slice(-1)[0];

  Object.entries(mediaQueries).forEach(([key, value]) => {
    if (prevValue < width && width <= value) {
      quality = webpQuality[key];
    }
    prevValue = value;
  });

  return quality;
};

const computeQualityParameter = (explicitQuality, imageWidth) => {
  if (explicitQuality) {
    return `&qlt=${explicitQuality}`;
  }
  return enableWebpQuality ? `&qlt=${getWebpQuality(imageWidth)}` : '';
};

const setTransformationTemplate = template => (template ? `&${template}` : '');

const renderImageUrl = (url, width, quality, transformationTemplate) => {
  if (!url) {
    return '/assets/images/no_product_image.png';
  }

  const imageURL = new URL(url.includes('https') ? url : `https:${url}`);
  const searchParams = imageURL.search ? `${imageURL.search}&` : '?';
  const bgParam = 'bg=rgb(242,242,242)';

  return `${imageURL.origin}${imageURL.pathname}${searchParams}w=${width}&${bgParam}${computeQualityParameter(
    quality,
    width
  )}${setTransformationTemplate(transformationTemplate)}`;
};

const renderImageUrlWithWidth = (url, size, quality, retinaMultiplier = 1, transformationTemplate) => {
  const width = parseInt(size * retinaMultiplier, 10);
  return `${renderImageUrl(url, width, quality, transformationTemplate)} ${width}w`;
};

const addWebpExtension = url => {
  const [path, query] = url.split('?');
  return `${path}.webp${(query && `?${query}`) || ''}`;
};

const getSrcsetURLs = (srcset, url, quality, transformationTemplate) => {
  const retinaMultiplierX2 = 2;
  const retinaMultiplierX3 = 3;
  const nonRetina = srcset.map(size => renderImageUrlWithWidth(url, size, quality, 1, transformationTemplate));
  const x2 = srcset.map(size =>
    renderImageUrlWithWidth(url, size, quality, retinaMultiplierX2, transformationTemplate)
  );
  const x3 = srcset.map(size =>
    renderImageUrlWithWidth(url, size, quality, retinaMultiplierX3, transformationTemplate)
  );
  return [...new Set([...nonRetina, ...x2, ...x3])];
};

const getSrcsets = sizes => {
  let prevSize = '100vw';
  const sizesAvailable = Object.keys(mediaQueries).map(key => {
    const currentSize = sizes[key] ? sizes[key] : prevSize;
    prevSize = currentSize;
    return {
      [key]: currentSize,
    };
  });

  const srcSets = sizesAvailable.map(sizeObj => {
    const LAST_TWO_CHARACTERS = -2;
    const hundredPercent = 100;
    const sizeKey = Object.keys(sizeObj)[0];
    const sizeValue = sizeObj[sizeKey];
    const unit = sizeValue.slice(LAST_TWO_CHARACTERS);
    const mediaQuery = parseInt(sizeValue.replace(unit, ''), 10);
    return unit === 'px' ? mediaQuery : Math.round((mediaQueries[sizeKey] * mediaQuery) / hundredPercent);
  });

  return [...new Set(srcSets)];
};

const mediaQueriesMinWidth = () => {
  let prevKey = '';
  return Object.keys(mediaQueries)
    .map(key => {
      const currentMinWidth = mediaQueries[prevKey] ? mediaQueries[prevKey] + 1 : 0;
      prevKey = key;
      return {
        [key]: currentMinWidth,
      };
    })
    .reduce((prev, curr) => Object.assign(prev, curr), {});
};

const getSizes = sizes =>
  Object.keys(sizes)
    .map(sizeKey => `(min-width:${mediaQueriesMinWidth()[sizeKey]}px) ${sizes[sizeKey]}`)
    .reverse();

class Image extends Component {
  render() {
    const { alt, wrapperClassName, className, media, onPictureRef, sizes, style, ...restOfProps } = this.props;

    const { url: mediaUrl, quality, transformationTemplate = null } = media;
    const srcSets = getSrcsets(sizes);

    const imgUrl = renderImageUrl(mediaUrl, Math.min(...srcSets), null, transformationTemplate);
    const imgSizes = getSizes(sizes).join(',');
    const isWebp = !mediaUrl.match('/static/');
    const imgType = isWebp ? 'image/webp' : 'image/jpg';
    const srcSet = mediaUrl ? getSrcsetURLs(srcSets, mediaUrl, quality, transformationTemplate) : [];
    const imgSrcSetAttr = srcSet.join(',');
    const sourceSrcSetAttr = isWebp ? srcSet.map(addWebpExtension).join(',') : imgSrcSetAttr;

    return (
      <picture ref={onPictureRef} className={wrapperClassName}>
        <source sizes={imgSizes} srcSet={sourceSrcSetAttr} type={imgType} />
        <img
          alt={alt}
          className={className}
          sizes={imgSizes}
          src={imgUrl}
          srcSet={imgSrcSetAttr}
          style={style}
          {...restOfProps}
        />
      </picture>
    );
  }
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  media: PropTypes.shape({
    url: PropTypes.string.isRequired,
    quality: PropTypes.number,
    transformationTemplate: PropTypes.string,
  }).isRequired,
  onPictureRef: PropTypes.func,
  sizes: PropTypes.shape({
    l: PropTypes.string,
    s: PropTypes.string,
  }).isRequired,
  style: PropTypes.objectOf(PropTypes.string),
};

Image.defaultProps = {
  alt: '',
  className: '',
  onPictureRef: null,
  style: {},
};

export default Image;
