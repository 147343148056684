import { find } from 'lodash';
import { DL_EVENT_ADD_TO_CART, DL_EVENT_REMOVE_FROM_CART, DL_EVENT_VIEW_CART } from './gtmAnalyticsConstants';
import { getPLPOrSRPProps } from './productListAnalytics';
import {
  getProductData,
  replaceSpecialChars,
  formatDataLayer,
  getProductName,
  getAttributeFromClassificationData,
  clearEcommerceAndPushToDataLayer,
  getProductEcommerceObject,
  getCartEcommerceObject,
  convertStringCurrencyToNumber,
  getStockLevel,
} from './gtmAnalyticsUtils';

const { locale, pageType } = window.inlineGlobalConfig;

export function dlViewCart(cartItems) {
  clearEcommerceAndPushToDataLayer(DL_EVENT_VIEW_CART, getCartEcommerceObject(cartItems));
}

const getTotalUnitPrice = (product, cart) => {
  const findProductInCart = cart.entries.find(p => p.id === product.id);
  const totalUnitPrice = convertStringCurrencyToNumber(findProductInCart.totalPrice) / findProductInCart.quantity;
  return totalUnitPrice;
};

function dlRemoveFromCart(product) {
  clearEcommerceAndPushToDataLayer(DL_EVENT_REMOVE_FROM_CART, getProductEcommerceObject(product));
}

export function getListPropsForAddToBagAndAddToWishlist(index) {
  const nullifyForPDP =
    pageType === 'product'
      ? {
          item_list_id: undefined,
          item_list_name: undefined,
        }
      : {};

  return { ...getPLPOrSRPProps(pageType, index), ...nullifyForPDP };
}

export function dlRemoveOneFromCart(product, cart) {
  dlRemoveFromCart({ ...product, quantity: 1, totalPrice: getTotalUnitPrice(product, cart) });
}

export function dlRemoveAllFromCart(product) {
  dlRemoveFromCart(product);
}

export function dlAddOneMoreToCart(product, cart) {
  dlAddToCart({ ...product, quantity: 1, totalPrice: getTotalUnitPrice(product, cart) });
}

export function dlAddToCart(product, listProps) {
  // eslint-disable-next-line camelcase
  clearEcommerceAndPushToDataLayer(
    DL_EVENT_ADD_TO_CART,
    getProductEcommerceObject({ ...product, stock_level: getStockLevel(product) }, listProps)
  );
}

export function dlTrackProductDecrease({ product, location, quantity = 1, cart, successFlag }) {
  const productAnalytics = getProductData({
    ...product,
    selectedSize: product.variationValues[0].dataLayerName,
  });

  window.dataLayer.push({ bag: undefined });
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'remove from bag',
    bag_value: `-${productAnalytics.price * quantity}`,
    success_flag: successFlag ? 'success' : 'error',
    ecommerce: {
      currencyCode: locale.currentCurrency,
      remove: {
        products: [
          {
            id: product.id,
            quantity,
            ...productAnalytics,
          },
        ],
      },
    },
    remove_from_bag_loc: location,
    bag: generateBagObject(cart),
  });
}

export function dlTrackProductIncrease({ product, location, selectedSize, cart, successFlag }) {
  const productAnalytics = getProductData({
    ...product,
    selectedSize,
  });

  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'add to bag',
    bag_value: productAnalytics.price,
    success_flag: successFlag ? 'success' : 'error',
    ecommerce: {
      currencyCode: locale.currentCurrency,
      add: {
        products: [
          {
            id: product.id,
            quantity: 1,
            ...productAnalytics,
          },
        ],
      },
    },
    add_to_bag_loc: location,
    bag: generateBagObject(cart),
  });
}

export function dlTrackAddToBagError({ product, selectedSize }) {
  try {
    const productAnalytics = getProductData({
      ...product,
      selectedSize,
    });

    window.dataLayer.push({
      add_to_bag_loc: replaceSpecialChars(pageType),
      event: 'visitor interaction',
      interaction_name: 'add to bag',
      bag_value: productAnalytics.price,
      success_flag: 'error - sorry the last one is already in your bag',
      ecommerce: {
        currencyCode: locale.currentCurrency,
        add: {
          products: [
            {
              quantity: 1,
              ...productAnalytics,
            },
          ],
        },
      },
    });
  } catch (error) {
    console.error(error);
  }
}

export function dlTrackViewBag(cartItems) {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'view bag',
    success_flag: 'success',
    bag: generateBagObject(cartItems),
  });
}

function generateBagObject(bagInfo) {
  const { entries, totalItems, totalPriceValue, totalPrice } = bagInfo;
  const products = getProductItems(entries);

  return {
    id: bagInfo.id,
    currencyCode: locale.currentCurrency,
    total_quantity: totalItems,
    total_price:
      (totalPriceValue && totalPriceValue.toFixed(2)) ||
      (totalPrice && totalPrice.replace(/^\D+/g, '').replace(/,/g, '.')),
    products,
  };
}

function getProductItems(products) {
  return products.map(product => {
    const { baseId, link, quantity, variationValues, dataLayerMap, price, baseImage, creationtime } = product;

    const unitPrice = price.replace(/^\D+/g, '').replace(/,/g, '.');
    const color = getAttributeFromClassificationData(dataLayerMap, 'color');
    const productData = Object.entries({
      id: baseId,
      name: `${getProductName(product.name || '')} ${product.dataLayerDescription || ''} ${color}`,
      variant: color,
      size: variationValues[0].dataLayerName,
      technology: find(dataLayerMap.entry, entry => entry.key === 'technology').value,
      class: find(dataLayerMap.entry, entry => entry.key === 'clearanceStatus').value,
      price: unitPrice,
      quantity,
      total_item_price: (quantity * unitPrice).toFixed(2),
    }).reduce(formatDataLayer, {});

    productData.image = `https:${baseImage}`;
    productData.url = window.location.origin + link;
    productData.added_on = creationtime.replace(/[^0-9-:]/g, ' ').trim();

    return productData;
  });
}
