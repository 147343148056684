export function isSpacePressed({ which }) {
  return which === 32;
}

export function isEnterPressed({ which }) {
  return which === 13;
}

export function isEscPressed({ which }) {
  return which === 27;
}

export function isUpArrowPressed({ which }) {
  return which === 38;
}

export function isDownArrowPressed({ which }) {
  return which === 40;
}

export function isRightArrowPressed({ which }) {
  return which === 39;
}

export function isLeftArrowPressed({ which }) {
  return which === 37;
}

export function isTabPressed({ which }) {
  return which === 9;
}

export function isUserTabbing() {
  return document.querySelector('body').classList.contains('user-is-tabbing');
}

export function getFocusableElements(root = document) {
  return root.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
}
