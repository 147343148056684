import { saveStorageData } from 'app/utils/localStorage/localStorage';
import { isEmpty } from 'lodash';
import { customFetch, isJsonString } from './ajaxHelpers';
import { REMOVE_FOOTER_INVISIBILITY } from '../cmscomponents/footer/footerConstants';

const { loggedUserInfoUrl, pageType, baseStoreUrl, locale } = window.inlineGlobalConfig;

window.globalData = window.globalData || {};

window.globalData.cartInfo =
  window.globalData.cartInfo ||
  (() => {
    let resolveCartInfoPromise = null;
    const data = new Promise(resolve => {
      resolveCartInfoPromise = resolve;
    });
    return {
      set(cartInfo) {
        resolveCartInfoPromise(cartInfo);
      },
      get() {
        return data;
      },
    };
  })();

export function setGlobalCartInfo(cartInfo) {
  window.globalData.cartInfo.set(cartInfo);
  return cartInfo;
}

export function getGlobalCartInfo() {
  return window.globalData.cartInfo.get();
}

// TOOD: Refactor to merge with cartInfo
// -----------------------------------------
window.globalData.wishlistInfo =
  window.globalData.wishlistInfo ||
  (() => {
    let setInfo;
    return {
      set(wishlistInfo) {
        setInfo = wishlistInfo;
      },
      get() {
        return new Promise(resolve => {
          const intervalId = setInterval(() => {
            if (setInfo) {
              clearInterval(intervalId);
              resolve(setInfo);
            }
          }, 200);
        });
      },
    };
  })();

export function setGlobalWishlistInfo(wishlistInfo) {
  window.globalData.wishlistInfo.set(wishlistInfo);
  return Promise.resolve(wishlistInfo);
}

export function getGlobalWishlistInfo() {
  return window.globalData.wishlistInfo.get();
}
// -----------------------------------------

export function getLoggedUserInfo() {
  if (!window.globalData.loggedUserInfo) {
    window.globalData.loggedUserInfo = customFetch('GET', `${loggedUserInfoUrl}&_=${Date.now()}`).then(
      xhttp =>
        new Promise((resolve, reject) => {
          /* eslint-disable no-param-reassign */
          xhttp.onload = () =>
            resolve(isJsonString(xhttp.responseText) ? JSON.parse(xhttp.responseText) : xhttp.responseText);
          xhttp.onerror = () => reject(xhttp.statusText);
          /* eslint-enable no-param-reassign */
        })
    );
  }
  return window.globalData.loggedUserInfo;
}

export function isGuestUser() {
  return getLoggedUserInfo().then(userInfo => isEmpty(userInfo['userInfo/index'].username));
}

export function getBaseStoreData() {
  if (!window.globalData.baseStoreData) {
    window.globalData.baseStoreData = customFetch('GET', baseStoreUrl).then(
      xhttp =>
        new Promise((resolve, reject) => {
          /* eslint-disable no-param-reassign */
          xhttp.onload = () =>
            resolve(isJsonString(xhttp.responseText) ? JSON.parse(xhttp.responseText) : xhttp.responseText);
          xhttp.onerror = () => reject(xhttp.statusText);
          /* eslint-enable no-param-reassign */
        })
    );
  }
  return window.globalData.baseStoreData;
}

export function getCurrentStoreData() {
  return getBaseStoreData().then(baseStore =>
    baseStore.stores.find(store => store.uid === locale.country.toUpperCase())
  );
}

export function displayFooter() {
  window.globalData.isFooterDisplayed = window.globalData.isFooterDisplayed || false;

  if (!window.globalData.isFooterDisplayed) {
    const footer = document.querySelector('.footer');

    if (footer) {
      footer.classList.remove('invisible');
    } else {
      saveStorageData(REMOVE_FOOTER_INVISIBILITY, 'true');
    }

    if (pageType === 'bag') {
      try {
        const $mediaBanner = document.querySelector('.cart__media-banner');
        if ($mediaBanner) {
          $mediaBanner.classList.remove('invisible');
        }
      } catch (e) {
        console.error(e);
      }
    }

    window.globalData.isFooterDisplayed = true;
  }
}

export function waitPageLoadAnalytics() {
  if (!window.globalData.isPageAnalyticsPushed) {
    window.globalData.isPageAnalyticsPushed = new Promise(resolve => {
      if (isPageLoadAnalyticsPushed()) {
        resolve();
      } else {
        setInterval(() => {
          if (isPageLoadAnalyticsPushed()) {
            resolve();
          }
        }, 200);
      }
    });
  }
  return window.globalData.isPageAnalyticsPushed;
}

function isPageLoadAnalyticsPushed() {
  for (let i = 0; i < window.dataLayer.length; i++) {
    if (window.dataLayer[i].ga_account) {
      return true;
    }
  }
  return false;
}
