import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage as Message } from 'formik';

function ErrorMessage({ ...props }) {
  return <Message component="span" className="form-msg-error" role="alert" {...props} />;
}

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ErrorMessage;
