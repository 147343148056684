export const extractProductClearanceStatus = classificationDataAttributes => {
  const [status] = classificationDataAttributes.entry.filter(item => item.key === 'clearanceStatus').map(toQubitFormat);
  return status;
};

// Fn with side-effect: adds data attributes to <div id='pdpDetails'> node (mount point for PDP Root app)
// values must be set dynamically, since changing the colour variants should change the data-sku value
export const updateQubitAttributesOnProductDetailsDOMNode = pdpProductData => {
  const pdpDetailsDOMElement = document.querySelector('#pdpDetails');
  const { productCode, dataLayerMap } = pdpProductData;
  const clearanceStatus = extractProductClearanceStatus(dataLayerMap);

  pdpDetailsDOMElement.setAttribute('data-classification-status', clearanceStatus);
  pdpDetailsDOMElement.setAttribute('data-sku', productCode);

  return pdpProductData;
};

function toQubitFormat(classificationAttribute) {
  return (classificationAttribute.value || '').toLowerCase().replace(' ', '');
}
