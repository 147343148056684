import { isPlainObject, isString } from 'lodash';
import { saveStorageData } from 'app/utils/localStorage/localStorage';
import { SESSION_STORAGE } from 'app/utils/localStorage/localStorageConstants';
import {
  TYPE_ERROR,
  TYPE_SUCCESS,
  TYPE_WARNING,
  SHOW_MESSAGE_EVENT,
  HIDE_MESSAGE_EVENT,
  FLASH_MESSAGES_STORAGE,
  TYPE_DELIVERY_THRESHOLD,
} from './globalMessagesConstants';
import { getGlobalMessagesRoot, isServiceActive } from './globalMessagesUtils';

/*
  TODO Method candidates:
  showStickyMessage
  method to scrollToTop and show message
 */

export function showMessage(messageDescriptor) {
  if (isServiceActive()) {
    getGlobalMessagesRoot().dispatchEvent(new CustomEvent(SHOW_MESSAGE_EVENT, { detail: messageDescriptor }));
  } else {
    saveStorageData(FLASH_MESSAGES_STORAGE, messageDescriptor, SESSION_STORAGE);
  }
}

export function hideMessage() {
  getGlobalMessagesRoot().dispatchEvent(new CustomEvent(HIDE_MESSAGE_EVENT, {}));
}

export function showSuccess(messageDescriptor) {
  showMessage({ ...messageDescriptor, type: TYPE_SUCCESS });
}

export function showWarning(messageDescriptor) {
  showMessage({ ...messageDescriptor, type: TYPE_WARNING });
}

export function showError(messageDescriptor) {
  showMessage({ ...messageDescriptor, type: TYPE_ERROR });
}

// thresholdAttrs = autoHide, displayTime, sticky, customColor, message
export function showDeliveryThreshold(thresholdAttrs) {
  const messageDescriptor = createMessageDescriptor(thresholdAttrs.message, TYPE_DELIVERY_THRESHOLD, thresholdAttrs);
  showMessage({ ...messageDescriptor, type: TYPE_DELIVERY_THRESHOLD });
}

export function flashMessage(message, type) {
  const messageDescriptor = createMessageDescriptor(message, type);
  saveStorageData(FLASH_MESSAGES_STORAGE, messageDescriptor, SESSION_STORAGE);
}

export function flashSuccessMessage(message) {
  flashMessage(message, TYPE_SUCCESS);
}

export function flashWarningMessage(message) {
  flashMessage(message, TYPE_WARNING);
}

export function flashErrorMessage(message) {
  flashMessage(message, TYPE_ERROR);
}

function createMessageDescriptor(message, type, attrs = {}) {
  if (!message || !type) {
    throw new Error('Missing mandatory arguments: "message" or "type"');
  }
  if (type === TYPE_DELIVERY_THRESHOLD) {
    return {
      ...attrs,
      type,
    };
  }
  if (isString(message)) {
    return {
      messageId: message,
      type,
      sticky: true, // TODO On My Account messages should not be sticky
    };
  }
  if (isPlainObject(message)) {
    return {
      sticky: true,
      ...message,
      type,
    };
  }
  throw new Error('Could not create the message descriptor');
}
