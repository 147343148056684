/* eslint-disable camelcase */
import { omit, isNumber, isBoolean } from 'lodash';
import { PAYMENT_METHOD } from 'app/checkout/components/checkoutPayment/checkoutPaymentConstants';
import { getSizeData } from 'app/productDetails/productDetailsUtils';
import {
  DL_CHECKOUT_TYPE_APPLE_PAY,
  DL_CHECKOUT_TYPE_PAYPAL_EXPRESS,
  DL_CHECKOUT_TYPE_WEBSITE,
} from './gtmAnalyticsConstants';

const { locale } = window.inlineGlobalConfig;
const currency = locale.currentCurrency;

const clearanceStatusMap = cStatus => {
  if (cStatus === 'normal') {
    return 'full price';
  }
  if (cStatus === 'sale') {
    return 'markdown';
  }
  return cStatus?.toLowerCase();
};

const timestamp = () => Date.now();

const clearEcommerce = () => {
  window.dataLayer.push({
    ecommerce: null,
  });
};

export const convertStringCurrencyToNumber = price => {
  if (typeof price === 'number') {
    return price;
  }

  return price && Number(price.replace(/^\D+/g, '').replace(/,/g, '.'));
};

const getValue = eventData => {
  const { totalPriceValue, totalPrice, price } = eventData;
  return totalPriceValue || convertStringCurrencyToNumber(totalPrice) || convertStringCurrencyToNumber(price);
};

const getCartValue = eventData => {
  const { totalPriceWithoutTax } = eventData;
  return convertStringCurrencyToNumber(totalPriceWithoutTax);
};

const getOrderValue = eventData => {
  const { totalPrice } = eventData;
  return totalPrice?.value;
};

const fixFloatingPointPrecission = num => {
  return Number.parseFloat(num.toFixed(2));
};

const getDiscountValueNew = (price, wasPrice) => fixFloatingPointPrecission(wasPrice - price);

const getCoupon = eventData => {
  const { appliedVouchers, declinedCoupon, appliedVoucher } = eventData;
  const coupon = declinedCoupon || (appliedVouchers && appliedVouchers[0]?.voucherCode) || appliedVoucher || '';
  return coupon === '' ? undefined : coupon.toLowerCase();
};

const getCustomerType = () => {
  if (window.inlineOrderConfirmation.orderConfirmation) {
    return window.inlineOrderConfirmation.orderConfirmation.newCustomer ? 'new customer' : 'existing customer';
  }

  return null;
};

const getAttributeFromClassificationDataNew = (classificationData, attribute) => {
  const data =
    classificationData.entry?.find(group => group.key === attribute) ||
    classificationData.entry?.find(group => group.key === `${attribute}_en_string`) ||
    classificationData.entry?.find(group => group.key === `${attribute}_en_uk_string`);

  return data?.value || undefined;
};

const getStockCount = (code, data) => data.find(s => s.sku === code)?.count;
const getStockLevelAtCartAndWishlist = product => product.stockLevel;
const getStockLevelAtPDP = product => getStockCount(product.code, getSizeData(product));
const getStockLevelAtPLP = product => getStockCount(product.code, product.details?.sizes);
export const getStockLevel = product =>
  getStockLevelAtCartAndWishlist(product) || getStockLevelAtPDP(product) || getStockLevelAtPLP(product);

const isProductOutOfStock = product => product?.stockLevel <= 0;

export const getCheckoutType = paymentInfo => {
  switch (paymentInfo?.type) {
    case undefined:
    case null:
      return { checkout_type: undefined };
    case PAYMENT_METHOD.PAYPAL_EXPRESS:
      return { checkout_type: DL_CHECKOUT_TYPE_PAYPAL_EXPRESS };
    case PAYMENT_METHOD.APPLE_PAY:
      return { checkout_type: DL_CHECKOUT_TYPE_APPLE_PAY };
    default:
      return { checkout_type: DL_CHECKOUT_TYPE_WEBSITE };
  }
};

export const getProductName = name => name.replace(/<sup>™<\/sup>|<sup>&trade;<\/sup>/, '');

export const getProductAge = nrOfDays => {
  const productAgeTypes = [
    {
      value: 14,
      content: '2 weeks',
    },
    {
      value: 90,
      content: '3 months',
    },
    {
      value: 182,
      content: '6 months',
    },
    {
      value: 365,
      content: 'year',
    },
  ];
  const productAgeTypesLength = productAgeTypes.length;
  if (productAgeTypes[0].value > nrOfDays) {
    return `past ${productAgeTypes[0].content}`;
  }
  for (let i = 1; i < productAgeTypesLength; i++) {
    if (productAgeTypes[i - 1].value <= nrOfDays && nrOfDays < productAgeTypes[i].value) {
      return `past ${productAgeTypes[i].content}`;
    }
  }
  return 'past more than one year';
};

export const getLocaleDataByUid = uid => {
  switch (uid) {
    case 'uk':
      return {
        country: 'united kingdom',
        gaAccount: 'UA-17766798-1',
        currency: '£',
      };
    case 'ca':
      return {
        country: 'canada',
        gaAccount: 'UA-17766798-4',
        currency: 'C$',
      };
    case 'us':
      return {
        country: 'united states',
        gaAccount: 'UA-17766798-2',
        currency: '$',
      };
    case 'eu':
      return {
        country: 'europe',
        gaAccount: 'UA-17766798-3',
        currency: '€',
      };
    default:
      return {
        country: 'united kingdom',
        gaAccount: 'UA-17766798-1',
        currency: '£',
      };
  }
};

export const getLanguageName = language => {
  switch (language) {
    case 'en':
      return 'english';
    case 'de':
      return 'german';
    case 'fr':
      return 'french';
    case 'it':
      return 'italian';
    default:
      return 'english';
  }
};

export const getDiscountLevel = (price, wasPrice) => {
  const maxPercentage = 100;
  const discountPercentage = ((wasPrice - price) * maxPercentage) / wasPrice;
  switch (true) {
    case 0 < discountPercentage && discountPercentage < 20: // eslint-disable-line
      return 'less than 20%';
    case 20 <= discountPercentage && discountPercentage < 35: // eslint-disable-line
      return '20% to 35%';
    case 35 <= discountPercentage && discountPercentage < 50: // eslint-disable-line
      return '35% to 50%';
    case 50 <= discountPercentage && discountPercentage < 70: // eslint-disable-line
      return '50% to 70%';
    case discountPercentage >= 70: // eslint-disable-line
      return '70%+';
    default:
      return '';
  }
};

export const getDiscountValue = (price, wasPrice) => (wasPrice - price).toFixed(2); // eslint-disable-line

export const getProductAvailability = sizes => {
  const maxPercentage = 100;
  let numberOfNoStockSizes = 0;
  sizes.forEach(size => {
    if (size.count <= 0) {
      numberOfNoStockSizes += 1;
    }
  });
  return Math.round(maxPercentage - (numberOfNoStockSizes * maxPercentage) / sizes.length);
};

export const parseToGTMFloat = number => parseFloat(number).toFixed(2); // eslint-disable-line

export const getAttributeFromClassificationData = (classificationData, attribute) =>
  (() =>
    classificationData.entry.filter(group => group.key === attribute)[0] ||
    classificationData.entry.filter(group => group.key === `${attribute}_en_string`)[0] ||
    classificationData.entry.filter(group => group.key === `${attribute}_en_uk_string`)[0] || {
      value: '',
    })().value;

export const formatDataLayer = (acc, [key, value]) => {
  const newValueFormat =
    value && !isNumber(value) && !isBoolean(value)
      ? value
          .toLowerCase()
          .replace(/&/gi, 'and')
          .replace(/_/gi, ' ')
          .replace(/ü/g, 'u')
          .replace(/[^A-Za-z0-9\s./-]/g, '')
          .trim()
      : value;

  return {
    ...acc,
    [key]: newValueFormat,
  };
};

export const getProductData = product => {
  const genderMap = {
    women: 'womens',
    femme: 'womens',
    donna: 'womens',
    damen: 'womens',
    men: 'mens',
    homme: 'mens',
    uomo: 'mens',
    herren: 'mens',
  };
  const classificationData = product.dataLayerMap || product.classificationData;
  const color = getAttributeFromClassificationData(classificationData, 'color');
  const gender = getAttributeFromClassificationData(classificationData, 'gender').toLowerCase();
  const silhouette = getAttributeFromClassificationData(classificationData, 'silhouette');
  const productCode = product.productCode || getAttributeFromClassificationData(classificationData, 'baseProductCode');
  const price = parseToGTMFloat(product.price.replace(/^\D+/g, '').replace(/,/g, '.'));
  const wasPrice = parseToGTMFloat(product.wasPrice.replace(/^\D+/g, '').replace(/,/g, '.'));
  const clearanceStatus = getAttributeFromClassificationData(classificationData, 'clearanceStatus');

  const productData = {
    variant: color,
    name: `${getProductName(product.name || '')} ${product.dataLayerDescription || ''} ${color}`,
    price,
    category: `${genderMap[gender]}/${silhouette}`,
    brand: getAttributeFromClassificationData(classificationData, 'family'),
    dimension1: `${getProductName(product.name || '')} ${product.dataLayerDescription || ''}`,
    dimension2: product.selectedSize || '',
    dimension3: productCode.split('-')[0],
    dimension4: productCode,
    dimension5: product.markdown ? 'reduced price' : 'full price',
    dimension6: product.markdown ? getDiscountLevel(price, wasPrice) : '',
    dimension7: product.markdown ? getDiscountValue(price, wasPrice) : '',
    dimension8: getAttributeFromClassificationData(classificationData, 'technology'),
    dimension9: getAttributeFromClassificationData(classificationData, 'material'),
    dimension10: getProductAge(product.productAge ? product.productAge : 0),
    dimension14: replaceSpecialChars(product.crossSell || ''),
    dimension15: clearanceStatusMap(clearanceStatus.toLowerCase()),
  };
  return Object.entries(productData).reduce(formatDataLayer, {});
};

export const positionRemapping = position => {
  switch (position) {
    case 'media-banner__copy-top-left':
      return 'top left';
    case 'media-banner__copy-top-center':
      return 'top center';
    case 'media-banner__copy-top-right':
      return 'top right';
    case 'media-banner__copy-middle-left':
      return 'middle left';
    case 'media-banner__copy-middle-center':
      return 'middle center';
    case 'media-banner__copy-middle-right':
      return 'middle right';
    case 'media-banner__copy-bottom-left':
      return 'bottom left';
    case 'media-banner__copy-bottom-center':
      return 'bottom center';
    case 'media-banner__copy-bottom-right':
      return 'bottom right';
    case 'media-buttons-banner__copy--center':
      return 'center';
    case 'media-buttons-banner__copy--left':
      return 'left';
    case 'media-buttons-banner__copy--right':
      return 'right';
    default:
      return position;
  }
};

export const findParentRowComponent = element => {
  if (element.nodeName === 'BODY' || element.nodeName === 'HTML') {
    return '';
  }
  if (element.parentNode.classList.contains('row-component')) {
    return element.parentNode.dataset.slotName;
  }
  return findParentRowComponent(element.parentNode);
};

export function replaceSpecialChars(string = '') {
  return string
    .replace(/[_-]/g, ' ')
    .replace(/&/g, ' and ')
    .replace(/\n/gi, '')
    .replace(/[^A-Za-z0-9\s.]/g, '')
    .replace(/\s\s+/g, ' ')
    .replace(/%/g, '')
    .toLowerCase();
}

export const checkForTextExistence = (element, type) => {
  if (element && element.dataset[type]) {
    const datasetText = element.dataset[type].replace('<br>', ' ');
    const placeholder = document.createElement('div');
    placeholder.innerHTML = datasetText;
    return placeholder.innerText || placeholder.textContent;
  }
  return '';
};

export const reduceProductFields = productsAnalytics =>
  productsAnalytics.map(product =>
    omit(product, ['brand', 'variant', 'dimension1', 'dimension3', 'dimension8', 'dimension9'])
  );

export const getElementIndex = el => Array.prototype.indexOf.call(el.parentNode.children, el);

export const pushAnalyticsOnTabClick = e => {
  try {
    window.dataLayer.push({
      event: 'visitor interaction',
      interaction_name: 'tab click',
      tab_name: e.target.innerText.toLowerCase().trim() || '',
    });
  } catch (err) {
    console.error(err);
  }
};

const getDataLayerItem = (product, otherProps) => {
  const {
    classificationData,
    code,
    dataLayerDescription,
    dataLayerMap,
    id,
    markdown,
    name,
    productAge,
    product_availability,
    quantity = 1,
    selectedSize,
    stock_level,
    variationValues,
  } = product;

  const { index, item_list_id, item_list_name, coupon } = otherProps || {};

  const features = dataLayerMap || classificationData;
  const color = getAttributeFromClassificationDataNew(features, 'color');
  const productCode = product.productCode || getAttributeFromClassificationDataNew(features, 'baseProductCode') || id;
  const totalValue = convertStringCurrencyToNumber(product.totalPrice);
  const price = convertStringCurrencyToNumber(product.price);
  const wasPrice = convertStringCurrencyToNumber(product.wasPrice);
  const clearanceStatus = getAttributeFromClassificationDataNew(features, 'clearanceStatus')?.toLowerCase();

  const productData = {
    coupon,
    discount: markdown ? getDiscountValueNew(price, wasPrice) : null, // dimension7
    discount_band: markdown ? getDiscountLevel(price, wasPrice) : undefined, // dimension6
    discount_flag: markdown ? 'reduced price' : 'full price', // dimension5
    index: index === undefined ? index : index + 1,
    item_age: getProductAge(productAge || 0), // dimension10
    item_brand: getAttributeFromClassificationDataNew(features, 'family'),
    item_category: getAttributeFromClassificationDataNew(features, 'gender'),
    item_category2: getAttributeFromClassificationDataNew(features, 'silhouette'),
    item_category3: getAttributeFromClassificationDataNew(features, 'technologyGroup'), // dimension8
    item_category4: undefined,
    item_category5: undefined,
    item_id: productCode,
    item_list_id,
    item_list_name,
    item_material: getAttributeFromClassificationDataNew(features, 'materialGroup'), // dimension9
    item_name: `${getProductName(name || '')} ${dataLayerDescription || ''} ${color || ''}`,
    item_nav_code: productCode, // dimension4
    item_size: selectedSize || (variationValues && variationValues[0].dataLayerName) || undefined, // dimension2
    item_style_code: productCode.split('-')[0], // dimension3
    item_trading_clasification: clearanceStatusMap(clearanceStatus), // dimension15
    item_variant: color,
    out_of_stock_flag: isProductOutOfStock(product),
    price,
    product_availability,
    quantity,
    sku: code,
    stock_level,
    totalValue,
    wasPrice,
  };

  return Object.entries(productData).reduce(formatDataLayer, {});
};

const getProductItems = (products, otherProps) => products.map(product => getDataLayerItem(product, otherProps));

export function getProductEcommerceObject(product, otherProps) {
  return {
    currency,
    value: getValue(product),
    items: [getDataLayerItem(product, otherProps)],
  };
}

export function getProductListEcommerceObject(products, otherProps) {
  return {
    currency,
    items: products.map((product, index) => getDataLayerItem(product, { ...otherProps, index })),
  };
}

const fillInTheSku = product => {
  if (!product.code) {
    product.code = product.id;
  }

  return product;
};

export function getCartEcommerceObject(bagInfo) {
  const { entries } = bagInfo;
  const coupon = getCoupon(bagInfo);
  const value = getCartValue(bagInfo);
  const otherProps = {
    coupon,
  };

  return {
    currency,
    coupon,
    value,
    items: getProductItems(entries.map(fillInTheSku), otherProps),
  };
}

export function getCheckoutEcommerceObject(bagInfo) {
  const {
    deliveryMode: { priceValue, deliveryCost, nameEn },
    paymentInfo,
  } = bagInfo;
  return {
    ...getCartEcommerceObject(bagInfo),
    shipping: priceValue ?? deliveryCost.value,
    shipping_tier: nameEn,
    payment_type: paymentInfo?.type,
  };
}

export function getPurchaseEcommerceObject(order) {
  const { code, totalTax } = order;
  return {
    ...getCheckoutEcommerceObject(order),
    value: getOrderValue(order),
    tax: totalTax.value,
    transaction_id: code,
    customer_type_flag: getCustomerType(),
  };
}

export const clearEcommerceAndPushToDataLayer = (event, ecommerce, additionalProps) => {
  clearEcommerce();
  const data = {
    event,
    timestamp: timestamp(),
    ecommerce,
    ...additionalProps,
  };
  window.dataLayer.push(data);
};
