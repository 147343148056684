import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isEscPressed } from 'app/utils/accessibility/accessibilityUtils';
import TrapFocus from 'app/utils/accessibility/trapFocus';
import './modal.scss';

class ModalWrapper extends Component {
  constructor() {
    super();

    this.htmlElement = document.querySelector('html');
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this);
    this.handleCloseOnEsc = this.handleCloseOnEsc.bind(this);
    this.modalBackdropRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { isModalOpen, isWithinSticky } = this.props;
    if (isModalOpen !== prevProps.isModalOpen) {
      if (isModalOpen) {
        this.htmlElement.classList.add('modal-is-opened');
      } else {
        this.htmlElement.classList.remove('modal-is-opened');
      }

      if (isModalOpen && isWithinSticky) {
        this.htmlElement.classList.add('modal-is-opened-within-sticky');
      } else {
        this.htmlElement.classList.remove('modal-is-opened-within-sticky');
      }
    }

    if (isModalOpen) {
      document.addEventListener('keydown', this.handleCloseOnEsc);
      const modalBackdrop = this.modalBackdropRef.current;
      modalBackdrop.addEventListener('click', this.handleBackgroundClick);
    }
  }

  getContent() {
    const { children, isHTMLContent } = this.props;

    if (isHTMLContent) {
      return <div dangerouslySetInnerHTML={{ __html: children }} />;
    }
    return children;
  }

  getTitle() {
    const { titleMsg, titleMsgID, titleMsgHTML } = this.props;

    let result;
    if (titleMsg) {
      result = <h2>{titleMsg}</h2>;
    } else if (titleMsgID) {
      result = (
        <h2>
          <FormattedMessage id={titleMsgID} />
        </h2>
      );
    } else if (titleMsgHTML) {
      result = <h2 dangerouslySetInnerHTML={{ __html: titleMsgHTML }} />;
    }

    return result ? (
      <div id="modal__window-heading" className="modal__window-heading">
        {result}
      </div>
    ) : null;
  }

  handleCloseOnEsc(e) {
    const { modalToggle, isModalOpen } = this.props;
    if (isEscPressed(e) && isModalOpen) {
      modalToggle();
      document.removeEventListener('keydown', this.handleCloseOnEsc);
    }
  }

  handleBackgroundClick(e) {
    const { modalToggle } = this.props;

    if (e.target === e.currentTarget) {
      modalToggle();
    }
  }

  render() {
    const { maxWidth, customParentClass, isModalOpen, modalToggle, isCloseOutside, isForbidClose, intl } = this.props;

    if (isModalOpen === false) {
      return null;
    }

    const closeButton = (
      <button
        type="button"
        className="modal__window-close icon-close"
        onClick={() => modalToggle()}
        aria-label={intl.formatMessage({ id: 'header.modal.close' })}
      />
    );
    const title = this.getTitle();
    const a11yAttrs = {
      role: 'dialog',
      'aria-modal': true,
    };
    if (title) {
      a11yAttrs['aria-labelledby'] = 'modal__window-heading';
    }

    return (
      <TrapFocus enabled={isModalOpen}>
        <div className="modal__backdrop" ref={this.modalBackdropRef}>
          {!isForbidClose && isCloseOutside && closeButton}
          <div {...a11yAttrs} className={`modal__window modal__window-size--${maxWidth} ${customParentClass}`}>
            {!isForbidClose && !isCloseOutside && closeButton}
            {title}
            <div className="modal__window-container">{this.getContent()}</div>
          </div>
        </div>
      </TrapFocus>
    );
  }
}

ModalWrapper.defaultProps = {
  isHTMLContent: false,
  titleMsg: '',
  titleMsgID: '',
  titleMsgHTML: '',
  isCloseOutside: false,
  isWithinSticky: false,
  isForbidClose: false,
  maxWidth: 500,
  customParentClass: '',
};

ModalWrapper.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  modalToggle: PropTypes.func.isRequired,
  isForbidClose: PropTypes.bool,
  customParentClass: PropTypes.string,
  maxWidth: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  isHTMLContent: PropTypes.bool,
  titleMsg: PropTypes.string,
  titleMsgID: PropTypes.string,
  titleMsgHTML: PropTypes.string,
  isCloseOutside: PropTypes.bool,
  isWithinSticky: PropTypes.bool,
};

export default injectIntl(ModalWrapper);
