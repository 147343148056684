import { dlAddToCart } from './addToBagAnalytics';
import { DL_EVENT_ADD_TO_WISHLIST } from './gtmAnalyticsConstants';
import {
  getProductData,
  replaceSpecialChars,
  clearEcommerceAndPushToDataLayer,
  getProductEcommerceObject,
} from './gtmAnalyticsUtils';
import { dlSelectItem, dlViewItemList, getBasicListProps } from './productListAnalytics';

const { locale, pageType } = window.inlineGlobalConfig;

export function dlSelectWishlistItem(product, index) {
  dlSelectItem(product, { ...getBasicListProps(pageType), index });
}

export function dlViewWishlistItemList(products) {
  dlViewItemList(products, getBasicListProps(pageType));
}

export function dlAddToWishlistNew(product, listProps) {
  clearEcommerceAndPushToDataLayer(DL_EVENT_ADD_TO_WISHLIST, getProductEcommerceObject(product, listProps));
}

export function dlAddToCartFromWishlist(product, index) {
  dlAddToCart(product, { index, ...getBasicListProps(pageType) });
}

export function dlAddToWishlist({ product, selectedSize }) {
  const { name, price, category, dimension2, dimension4, dimension6 } = getProductData({
    ...product,
    selectedSize,
  });

  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'add to wishlist',
    success_flag: selectedSize ? 'success' : 'please select a size',
    add_to_wishlist_loc: replaceSpecialChars(pageType),
    wl_prod_name: name,
    wl_prod_price: price,
    wl_prod_currency: locale.currentCurrency,
    wl_prod_category: category,
    wl_prod_size: dimension2,
    wl_prod_nav_code: dimension4 || product.id.toLowerCase(),
    wl_prod_discount_band: dimension6,
  });
}

export function dlRemoveFromWishlist({ product, location, operationResult = 'success' }) {
  const { name, price, category, dimension2, dimension4, dimension6 } = getProductData({
    ...product,
    selectedSize: product.variationValues[0].dataLayerName,
  });

  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'remove from wishlist',
    success_flag: operationResult.toLowerCase(),
    remove_from_wishlist_loc: location,
    wishlist_remove_reason:
      (product.isNotActive && 'discontinued') || (product.stockLevel < 1 && 'out of stock') || 'none',
    wl_prod_name: name,
    wl_prod_price: price,
    wl_prod_currency: locale.currentCurrency,
    wl_prod_category: category,
    wl_prod_size: dimension2,
    wl_prod_nav_code: dimension4,
    wl_prod_discount_band: dimension6,
  });
}
