export const CART_SOURCES = {
  FETCH_CART: 'fetchCart',
  UPDATE_CART_ITEM: 'updateCart',
  REMOVE_CART_ITEM: 'removeCartItem',
  VALIDATE_CART: 'validateOrder',
  HANDLE_CART_COUPON: 'handleCoupon',
};

export const CART_ACTIONS = {
  CHECKOUT_STARTED: 'checkoutStarted',
  CHECKOUT_FINISHED: 'checkoutFailed',
  CHECKOUT_DISABLED: 'checkoutDisabled',
  CHECKOUT_ENABLED: 'checkoutEnabled',
  PAYPAL_EXPRESS_STARTED: 'paypalExpressStarted',
  PAYPAL_EXPRESS_FINISHED: 'paypalExpressFinished',
};

export const CART_VALIDATION_FLAG = 'CART_VALIDATION_FLAG';

export const REDIRECTED_WITH_NOTIFICATION = 'REDIRECTED_WITH_NOTIFICATION';

export const INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK';
