import React from 'react';
import PropTypes from 'prop-types';
import { handleErrorClass } from 'app/formComponents/formComponentsUtils';
import { Field, connect } from 'formik';
import { pick } from 'lodash';
import { formConnect, getErrorMsgsInEnglish } from 'app/formComponents/formikHOC';
import { dlTrackFormFields, dlTrackFormSubmit } from 'app/analytics/formsAnalytics';

let isValueChangedBeforeBlur = false;

function Input({
  formik: { errors, touched, validateForm, handleBlur, handleChange, setFieldValue, ...settings },
  formContext: { formName, validations },
  className,
  name,
  onChange: customOnChange,
  onBlur: customOnBlur,
  onClick,
  ...props
}) {
  function onChange(e) {
    isValueChangedBeforeBlur = true;
    if (customOnChange) {
      customOnChange(e);
    } else {
      handleChange(e);
    }
  }

  function onBlur(e) {
    e.persist();
    if (isValueChangedBeforeBlur) {
      validateForm().then(currentErrors => {
        if (currentErrors[name]) {
          dlTrackFormSubmit({
            errorMsgs: pick(getErrorMsgsInEnglish(validations, currentErrors), [name]),
            formName,
          });
        } else {
          dlTrackFormFields({
            formName,
            formField: name,
          });
        }
        if (!customOnBlur) {
          handleBlur(e);
        }
      });
    }

    if (customOnBlur) {
      customOnBlur(e);
    }

    isValueChangedBeforeBlur = false;
  }

  function clickHandler() {
    if (onClick) onClick(settings);
  }

  return (
    <Field
      className={`${className} ${handleErrorClass({ errors, touched }, name)}`}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      onClick={clickHandler}
      {...props}
    />
  );
}

Input.defaultProps = {
  className: '',
  formik: {},
  name: '',
};

Input.propTypes = {
  formik: PropTypes.object,
  className: PropTypes.string,
  name: PropTypes.string,
};

export default connect(formConnect(Input));
