import { TOGGLE_PASSWORD_TYPE } from './passwordTextInputConstants';

const PasswordTextInputReducer = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_PASSWORD_TYPE:
      return {
        ...state,
        [action.typeKey]: action.isPassword,
      };
    default:
      return state;
  }
};

export default PasswordTextInputReducer;
