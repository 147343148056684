import { isEmpty, pickBy } from 'lodash';
import {
  ajaxRequest,
  replaceKeyName,
  scrollToPosition,
  isElementVisible,
  getElementPosition,
  getElementHeightNoPadding,
} from 'app/utils/helpers';
import { ANIMATION_TIME } from './formComponentsConstants';

const { getAddressData, validatePostcodeData } = window.inlineGlobalConfig;

const defaultScrollGutter = 20;
export const scrollToError = (containerSelector, extraGutter = defaultScrollGutter) => {
  const container = document.querySelector(containerSelector);
  const firstErrorInput = [...container.querySelectorAll('._is-error')].find(el => isElementVisible(el));

  if (!firstErrorInput) return;

  const elementScrollTo = firstErrorInput.length > 0 ? firstErrorInput : container;
  const stickyHeader = getElementHeightNoPadding(document.querySelector('.header.header--fixed')) + extraGutter;
  const scrollTop = getElementPosition(elementScrollTo) - stickyHeader;
  scrollToPosition(scrollTop, ANIMATION_TIME);
};

export const scrollToEl = (element, extraGutter = defaultScrollGutter) => {
  const container = typeof element === 'string' ? document.querySelector(element) : element;
  const stickyHeader = getElementHeightNoPadding(document.querySelector('.header.header--fixed')) + extraGutter;
  const scrollTop = getElementPosition(container) - stickyHeader;
  scrollToPosition(scrollTop, ANIMATION_TIME);
};

// TODO remove usage of this function. replace with utils.getQASAddressDetails()
export const getValidAddressDetails = (stringAddress, values, setValues, updateState) => {
  if (stringAddress !== null) {
    ajaxRequest('POST', getAddressData, { addressId: stringAddress.id }).then(addressDataResponse => {
      const formValuesForReplacement = pickBy(
        replaceKeyName(addressDataResponse, 'town', 'city'),
        (value, key) => value !== null && values[key] !== undefined
      );
      setValues(Object.assign({}, values, formValuesForReplacement));

      updateState({
        selectedRegion: formValuesForReplacement.region,
      });
    });
  }
};

export const validatePostcode = (formData, updateState) => {
  const postcodeFormLabel = document.querySelector('label[for=postalCode]');
  const postcodeFormField = document.querySelector('input[name=postalCode]');
  const incorrectPostcodeErrorMsg = document.querySelector('.address-form__incorrect-postcode-error');

  const postcodeError = () => {
    postcodeFormLabel.classList.remove('_is-error');
    postcodeFormField.classList.remove('_is-error');
    incorrectPostcodeErrorMsg.classList.remove('display-block');
  };

  postcodeFormField.removeEventListener('keydown', postcodeError);

  ajaxRequest('POST', validatePostcodeData, formData).then(validationResponse => {
    if (validationResponse.length > 0 && !isEmpty(validationResponse[0].id)) {
      updateState({
        postcodeEntries: validationResponse,
        showPostcodeSelectAddress: true,
      });
    } else if (validationResponse.length === 1 && isEmpty(validationResponse[0].id)) {
      postcodeFormLabel.classList.add('_is-error');
      postcodeFormField.classList.add('_is-error');
      incorrectPostcodeErrorMsg.classList.add('display-block');
      postcodeFormField.addEventListener('keydown', postcodeError);
    }
  });
};

export const handleErrorClass = ({ errors, touched }, fieldName) =>
  errors[fieldName] && touched[fieldName] ? '_is-error' : '';

export const getFieldErrorMessage = ({ errors, touched }, fieldName) => touched[fieldName] && errors[fieldName];

export const validateBy = (validationProcessor, errorsPopulator, validationRules) => values => {
  Object.entries(values).forEach(([key, value]) => {
    validationProcessor.validate(key, value, {}, values);
  });
  const getAllErrors = errorsPopulator.getAll(Object.keys(values));
  return Object.entries(getAllErrors)
    .filter(([key]) => (validationRules.rules[key] && validationRules.rules[key].required) || !isEmpty(values[key]))
    .reduce(
      (acc, [key, value]) => Object.assign({}, acc, Object.values(value)[0] ? { [key]: Object.values(value)[0] } : {}),
      {}
    );
};

export const getRequiredFields = validationRules =>
  Object.entries(validationRules)
    .filter(rule => rule[1].required)
    .map(([title]) => title);
