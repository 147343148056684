import { convertCurrencyToNumber } from 'app/utils/helpers';
import { getProductName } from '../gtmAnalyticsUtils';

const { currentCurrency } = window.inlineGlobalConfig.locale;
const { pageType, locale } = window.inlineGlobalConfig;

const getColorCode = product => {
  const colorCode = product?.classificationData?.entry?.find(entryObj => entryObj.key === 'colorCode').value ?? null;
  return colorCode ? `#${colorCode}` : '';
};

export const createImageSet = (baseUrl, queryPostfix, totalImages = 3) => {
  const composeMap = new Map();
  for (let i = 0; i < totalImages; i++) {
    composeMap.set(i, `${baseUrl}${i > 0 ? `_${i}` : ''}${queryPostfix ? `?${queryPostfix}` : ''}`);
  }
  return composeMap;
};

export const getBasket = ({ subtotal, subtotalWithTax, total, quantity, additionalValue = {} }) =>
  Object.assign(
    {},
    { quantity },
    total
      ? {
          total: {
            currency: currentCurrency,
            value: total,
          },
        }
      : {},
    subtotalWithTax
      ? {
          subtotalIncludingTax: {
            currency: currentCurrency,
            value: subtotalWithTax,
          },
        }
      : {},
    subtotal
      ? {
          subtotal: {
            currency: currentCurrency,
            value: subtotal,
          },
        }
      : {},
    additionalValue
  );

export const getBasketItem = (basket, entry) => {
  const {
    baseId,
    baseImage,
    description,
    id,
    name,
    price,
    primaryCategoryName,
    quantity,
    stockLevel,
    subtotal,
    url,
    totalPrice,
    link,
  } = entry;
  return {
    basket,
    product: {
      sku: id,
      productId: baseId,
      name,
      stock: stockLevel,
      price: {
        currency: currentCurrency,
        value: convertCurrencyToNumber(price),
      },
      url: url || link,
      description,
      categories: [primaryCategoryName],
      images: [...createImageSet(baseImage, 'w=375').values()],
    },
    quantity,
    subtotal: {
      currency: currentCurrency,
      value: convertCurrencyToNumber(subtotal || totalPrice),
    },
    subtotalIncludingTax: {
      currency: currentCurrency,
      value: convertCurrencyToNumber(subtotal || totalPrice),
    },
  };
};

export const getProductData = product =>
  Object.assign(
    {
      sku: product.sku || '',
      productId: product.productCode,
      name: getProductName(product.name),
      price: {
        currency: locale.currentCurrency,
        value: convertCurrencyToNumber(product.price),
      },
    },
    product.size ? { size: product.size } : null,
    pageType === 'product'
      ? {
          originalPrice: {
            currency: locale.currentCurrency,
            value: convertCurrencyToNumber(product.markdown ? product.wasPrice : product.price),
          },
          url: `${window.location.pathname}${getColorCode(product)}`,
          description: product.description,
          categories: [product.primaryCategoryName],
          images: product.imageSet.items ? [...product.imageSet.items.map(image => `${image.src}?w=375`)] : [''],
        }
      : null,
    {
      stock: product.stock === undefined ? '' : product.stock,
    }
  );

export const isEcViewEventFired = () => {
  const { events } = window.uv;
  return events.some(event => event.meta.type === 'ecView');
};
