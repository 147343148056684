import { createElement } from 'react';
import PropTypes from 'prop-types';
import { handleErrorClass } from 'app/formComponents/formComponentsUtils';
import { connect } from 'formik';
import { formConnect } from 'app/formComponents/formikHOC';
import { listify } from 'app/utils/helpers';

function Label({
  formik: { errors, touched },
  formContext: { requiredFields = [] },
  htmlFor,
  children,
  className,
  type,
}) {
  // eslint-disable-next-line react/no-children-prop
  return createElement(type, {
    className: `${className}
     ${
       listify(htmlFor)
         .map(inputName => handleErrorClass({ errors, touched }, inputName))
         .find(value => value) || ''
     }
     ${listify(htmlFor).find(inputName => requiredFields.includes(inputName)) ? 'form-input__label--required' : ''}`,
    htmlFor: type === 'label' ? htmlFor : null,
    children,
  });
}

Label.defaultProps = {
  type: 'label',
  formik: null,
  htmlFor: '',
  children: null,
  className: '',
};

Label.propTypes = {
  formik: PropTypes.object,
  htmlFor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  formContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]))
    .isRequired,
  type: PropTypes.string,
};

export default connect(formConnect(Label));
