export const TYPE_ERROR = 'alert';
export const TYPE_SUCCESS = 'success';
export const TYPE_WARNING = 'warning';
export const TYPE_DELIVERY_THRESHOLD = 'delivery';

export const MAX_DISPLAY_TIME = 5;

export const DEFAULT_ERROR_MSG = 'error.default.error.message';

export const FLASH_MESSAGES_STORAGE = 'FLASH_MESSAGES_STORAGE';
export const NOTIFICATIONS_SERVICE = 'NOTIFICATIONS_SERVICE';
export const STATUS_ACTIVE = 'STATUS_ACTIVE';

export const SHOW_MESSAGE_EVENT = 'showMessage';
export const HIDE_MESSAGE_EVENT = 'hideMessage';

export const HIGHEST_MESSAGE_PRIORITY = 1;
export const LOWEST_MESSAGE_PRIORITY = 10;
