import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getFocusableElements } from './accessibilityUtils';

const TrapFocus = ({ enabled, children }) => {
  const trapRef = React.createRef();
  useEffect(() => {
    const focusable = getFocusableElements(trapRef.current.firstChild);
    const firstFocusable = focusable[0]; // eslint-disable-line
    const lastFocusable = focusable[focusable.length - 1];
    let currentFocus = firstFocusable;
    let scrollY = null;

    const onTrapFocus = e => {
      if (!trapRef.current) {
        return;
      }

      const elInsideFocusableParent = trapRef.current.firstChild.contains(e.target);
      if (!elInsideFocusableParent && enabled) {
        if (currentFocus === firstFocusable) {
          lastFocusable.focus();
        } else if (currentFocus === lastFocusable) {
          firstFocusable.focus();
        }
      } else {
        currentFocus = e.target;
        if (!scrollY) {
          scrollY = window.scrollY; // eslint-disable-line
        }
      }

      if (enabled && scrollY) {
        window.scrollTo(window.scrollX, scrollY);
      }
    };

    document.addEventListener('focus', onTrapFocus, true);

    return function cleanup() {
      document.removeEventListener('focus', onTrapFocus, true);
    };
  });

  return <div ref={trapRef}>{children}</div>;
};

TrapFocus.propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool.isRequired,
};

export default TrapFocus;
