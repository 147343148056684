import { CARD_URL, PAYPAL_URL, KLARNA_URL } from '../../checkoutConstants';

export const PAYMENT_METHOD = {
  CARD: 'CARD',
  PAYPAL: 'PAYPAL',
  ZEROVALUE: 'ZEROVALUE',
  KLARNA: 'KLARNA',
  PAYPAL_EXPRESS: 'PAYPAL_EXPRESS',
  GOOGLE_PAY: 'GOOGLE_PAY',
  APPLE_PAY: 'APPLE_PAY',
  AFTERPAY: 'AFTERPAY'
};

export const CARD_ICON_MAPPER = {
  visa: 'visa',
  paypal: 'paypal',
  klarna: 'klarna',
  mc: 'mastercard',
  amex: 'americanexpress',
  discover: 'discover',
  afterpay: 'afterpay',
};

export const PAYMENT_TYPES_MAP = {
  [CARD_URL]: PAYMENT_METHOD.CARD,
  [PAYPAL_URL]: PAYMENT_METHOD.PAYPAL,
  [KLARNA_URL]: PAYMENT_METHOD.KLARNA,
};

export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
