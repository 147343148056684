import { DEFAULT_ERROR_MSG } from 'app/globalMessages/globalMessagesConstants';
import { showError } from 'app/globalMessages/globalMessagesClient';

const { accountLoginUrl } = window.inlineGlobalConfig;

const genericErrorHandler = statusCode => {
  const unauthorized = 401;
  const forbidden = 403;

  switch (statusCode) {
    case unauthorized:
    case forbidden:
      window.location = accountLoginUrl;
      break;
    default:
      window.scrollTo(0, 0);
      showError({
        messageId: DEFAULT_ERROR_MSG,
        autoHide: true,
      });
  }
};

export default genericErrorHandler;
