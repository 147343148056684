import { isArray } from 'lodash';

const iterateThroughObject = (object = {}, callback) => {
  Object.entries(object).forEach(([key, value]) => {
    callback(key, value);
  });
};

const ajaxFormQueryParams = data => {
  const bracketsRegEx = /\[\]$/;
  const query = [];

  const add = (key, value) => {
    let val;
    if (typeof value === 'function') {
      val = value();
    } else if (value === null || value === undefined) {
      val = '';
    } else {
      val = value;
    }
    query[query.length] = `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
  };

  const build = (prefix, obj) => {
    if (prefix) {
      if (isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          if (bracketsRegEx.test(prefix)) {
            add(prefix, obj[i]);
          } else {
            build(`${prefix}[${typeof obj[i] === 'object' ? i : ''}]`, obj[i]);
          }
        }
      } else if (obj && String(obj) === '[object Object]') {
        Object.entries(obj).forEach(([key, value]) => {
          build(`${prefix}[${key}]`, value);
        });
      } else {
        add(prefix, obj);
      }
    } else if (isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else if (obj) {
      Object.entries(obj).forEach(([key, value]) => {
        build(key, value);
      });
    }

    return query;
  };

  return build('', data).join('&').replace(/%20/g, '+');
};

export const customFetch = (
  method,
  url,
  formData,
  timeStampRequired = false,
  requestHeaders = {
    'Content-type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest',
  }
) =>
  new Promise(resolve => {
    const xhttp = new XMLHttpRequest();
    const params =
      requestHeaders['Content-type'] === 'application/json' ? JSON.stringify(formData) : ajaxFormQueryParams(formData);
    const requestUrl = method === 'GET' && params ? `${url}?${params}` : url;

    xhttp.open(method, timeStampRequired ? `${requestUrl}?t=${Date.now()}` : requestUrl, true);
    iterateThroughObject(requestHeaders, (key, value) => xhttp.setRequestHeader(key, value));
    xhttp.send(method === 'GET' ? undefined : params);

    resolve(xhttp);
  });

export const isJsonString = jsonString => {
  try {
    const o = JSON.parse(jsonString);
    return o && typeof o === 'object' && o !== null;
  } catch (e) {
    return false;
  }
};

export const loadAsyncScript = url =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      resolve(true);
    };

    script.onerror = () => {
      reject(new Error('Unable to load the script'));
    };
  });
