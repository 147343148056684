import { isArrayLike } from 'lodash';
import mediaQueries from 'app/utils/mediaQueries';
import { PAYMENT_METHOD } from 'app/checkout/components/checkoutPayment/checkoutPaymentConstants';
import { getCardIconsFromPaymentMethods } from 'app/checkout/components/checkoutPayment/checkoutPaymentUtils';
import { saveStorageData, popStorageData } from 'app/utils/localStorage/localStorage';
import { SESSION_STORAGE } from 'app/utils/localStorage/localStorageConstants';
import { redirectWithWarning } from './redirects';
import { customFetch, isJsonString } from './ajaxHelpers';
import { isGuestUser } from './globalDataHelpers';
import { CART_VALIDATION_FLAG, REDIRECTED_WITH_NOTIFICATION } from '../cart/cartConstants';

const { accountLoginUrl } = window.inlineGlobalConfig;
const { baseCartUrl } = window.inlineCartConfiguration;

export const isElementVisible = elem =>
  elem.offsetWidth > 0 || elem.offsetHeight > 0 || elem.getClientRects().length > 0;

export const isElementVisibleWithinParent = elem => elem.offsetParent !== null;

export const fadeIn = (fadeElement, speed, display) => {
  if (!fadeElement) {
    return;
  }
  const element = fadeElement;
  element.style.display = display || 'block';
  element.classList.add(`fade-in-${speed}`);
  setTimeout(() => {
    element.classList.remove(`fade-in-${speed}`);
  }, speed);
};

export const fadeOut = (fadeElement, speed, callback) => {
  const element = fadeElement;
  element.classList.add(`fade-out-${speed}`);
  setTimeout(() => {
    element.style.display = 'none';
    element.classList.remove(`fade-out-${speed}`);
    return callback || true;
  }, speed);
};

export const getElementHeight = element => {
  if (!element) {
    return 0;
  }
  const elementStyle = element.getAttribute('style');
  element.setAttribute('style', 'display: block; max-height: none;');
  const height = element.offsetHeight;
  element.setAttribute('style', elementStyle);
  return height;
};

export function getElementAbsoluteHeights(elements) {
  const elementHeights = [];
  [].forEach.call(elements, (element, i) => {
    element.classList.add('temporary-hide-element');
    const paddingTop = parseInt(window.getComputedStyle(element, null).getPropertyValue('padding-top'), 10);
    const paddingBottom = parseInt(window.getComputedStyle(element, null).getPropertyValue('padding-bottom'), 10);
    const elementHeight = element.clientHeight;
    elementHeights[i] = elementHeight - paddingTop - paddingBottom;
    element.classList.remove('temporary-hide-element');
  });
  return elementHeights;
}

export function topWrapperOffset(action, offset, className) {
  const html = document.querySelector('html');
  const wrapper = document.querySelector('#wrapper');
  if (action === 'open') {
    html.classList.add(className);
    wrapper.style.marginTop = `-${offset}px`;
  } else if (action === 'close') {
    html.classList.remove(className);
    wrapper.style.removeProperty('margin-top');
    window.scrollTo(0, offset);
  }
}

export function scrollToPosition(yPos, scrollDuration) {
  return new Promise(resolve => {
    const distance = yPos - window.scrollY;
    const currentPosition = window.scrollY;
    const denominator = 2;
    const cosParameter = distance / denominator;
    let scrollCount = 0;
    let oldTimestamp = performance.now();
    const step = newTimestamp => {
      scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
      if (scrollCount >= Math.PI || window.scrollY === yPos) {
        window.scrollTo(0, yPos);
        resolve();
        return;
      }
      window.scrollTo(
        0,
        currentPosition + (distance - Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
      );
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    };
    window.requestAnimationFrame(step);
  });
}

export const getElementPosition = element => {
  const bodyRect = document.body.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  return elementRect.top - bodyRect.top;
};

export const getElementHeightNoPadding = element => {
  if (element) {
    const computedStyle = getComputedStyle(element);
    return element.clientHeight - parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
  }
  return 0;
};

export const getParams = query => {
  if (!query) {
    return {};
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
    const paramsCopy = { ...params };
    const [key, value] = param.split('=');
    paramsCopy[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
    return paramsCopy;
  }, {});
};

export const getQueryParams = () => {
  const currentURL = window.location.href;
  return getParams(currentURL.substring(currentURL.indexOf('?')));
};

export const usernamePadding = () => {
  const naveAccountWelcomeEl = document.querySelector('.nav__account-label--name');
  if (naveAccountWelcomeEl) {
    if (mediaQueries.is_large_up()) {
      const navSignoutEl = document.querySelector('.nav__account-label--signout');
      const width = navSignoutEl && navSignoutEl.offsetWidth;
      const bonusWidth = 7;
      naveAccountWelcomeEl.style.paddingRight = `${width + bonusWidth}px`;
    } else {
      naveAccountWelcomeEl.style.removeProperty('padding-right');
    }
  }
};

export const findAncestorBySelector = (element, selector) => {
  let el = element.parentElement;

  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
  }

  if (typeof el.closest === 'function') {
    return element.closest(selector);
  }

  while (el) {
    if (el.matches(selector)) {
      return el;
    }
    el = el.parentElement;
  }

  return null;
};

export function debounce(func, wait, immediate) {
  let timeout;
  return function debounceCb(...args) {
    const context = this;
    const argsCopy = args;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, argsCopy);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, argsCopy);
    }
  };
}

export const debounceCallback = (wait = 300) => {
  let timeout;
  return (param, callback) => {
    if (timeout) {
      window.clearTimeout(timeout);
    }

    timeout = window.setTimeout(() => callback(param), wait);
  };
};

const handleRedirects = (xhttp, customErrorHandler) =>
  new Promise(resolve => {
    if (customErrorHandler && customErrorHandler.status === xhttp.status) {
      const { redirectHandler, redirectUrl, messageId } = customErrorHandler;
      redirectHandler(redirectUrl, messageId);
    }

    const statusCodeActionsMap = {
      403: async () => {
        const isGuest = await isGuestUser();
        if (isGuest) {
          redirectWithWarning(window.location.origin + baseCartUrl, 'checkout.sessionExpired.guest.error');
        } else {
          redirectWithWarning(window.location.origin + accountLoginUrl, 'checkout.sessionExpired.registeredUser.error');
        }
      },
    };

    if (Object.keys(statusCodeActionsMap).includes(`${xhttp.status}`)) {
      return statusCodeActionsMap[xhttp.status]();
    }

    return resolve(xhttp);
  });

export const ajaxRequest = (
  method,
  url,
  formData,
  customErrorHandler = null,
  timeStampRequired = false,
  requestHeaders = {
    'Content-type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': getMeta('_csrf'),
  }
) =>
  customFetch(method, url, formData, timeStampRequired, requestHeaders).then(
    xhttp =>
      new Promise((resolve, reject) => {
        /* eslint-disable no-param-reassign */
        xhttp.onload = () =>
          handleRedirects(xhttp, customErrorHandler).then(() =>
            resolve(isJsonString(xhttp.responseText) ? JSON.parse(xhttp.responseText) : xhttp.responseText)
          );
        xhttp.onerror = () => reject(xhttp.statusText);
        /* eslint-enable no-param-reassign */
      })
  );

export const decodeHtml = html => {
  const txt = document.createElement('textarea');
  const slashUnicode = '&#92;u002f';
  txt.innerHTML = html.replace(slashUnicode, '/');
  return txt.value;
};

export const convertCurrencyToNumber = value =>
  typeof value === 'string' ? parseFloat(parseFloat(value.replace(/[^\d.]/g, '')).toFixed(2)) || 0 : value;

export const listify = listOrItem => {
  if (typeof listOrItem === 'string' || !isArrayLike(listOrItem)) {
    return [listOrItem];
  }
  return listOrItem;
};

export const replaceKeyName = (obj, currentKeys, nextKeys) => {
  const arrayOfCurrentKeys = listify(currentKeys);
  const arrayOfNextKeys = listify(nextKeys);
  return Object.keys(obj).reduce((acc, key) => {
    const indexOfCurrentKey = arrayOfCurrentKeys.indexOf(key);
    if (obj[key] && typeof obj[key] === 'object' && !isArrayLike(obj[key]) && indexOfCurrentKey === -1) {
      return Object.assign(acc, {
        [key]: replaceKeyName(obj[key], currentKeys, nextKeys),
      });
    }
    if (indexOfCurrentKey !== -1) {
      return Object.assign(acc, {
        [arrayOfNextKeys[indexOfCurrentKey]]: obj[key],
      });
    }
    return Object.assign(acc, { [key]: obj[key] });
  }, {});
};

export const emptyObjectProperties = obj =>
  Object.entries(obj).reduce((prev, [key]) => Object.assign(prev, { [key]: '' }), {});

export function mkQueue({ batchSize: _batchSize, onBatch } = {}) {
  let batchSize = _batchSize;
  let currentPromise = null;
  let queue = [];

  function tick() {
    if (currentPromise) return;

    if (queue.length) {
      currentPromise = Promise.all(queue.splice(0, batchSize).map(cb => cb()))
        .then(onBatch)
        .then(() => {
          currentPromise = null;
          tick();
        });
    } else {
      currentPromise = null;
    }
  }

  return {
    enqueue(...cbs) {
      queue.push(...cbs);
      tick();
    },

    setBatchSizeAndRestart(newBatchSize) {
      queue = [];
      currentPromise = null;
      batchSize = newBatchSize;
    },
  };
}

function whichTransitionEvent() {
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  const element = document.createElement('element');
  const transitionEvent = Object.entries(transitions).find(([api]) => element.style[api] !== undefined);

  return transitionEvent ? transitionEvent[1] : null;
}

export function transitionEndEvent(element, callback = undefined) {
  return new Promise(resolve => {
    const transitionEvent = whichTransitionEvent();
    element.addEventListener(transitionEvent, function transCallback() {
      element.removeEventListener(transitionEvent, transCallback);
      return resolve(callback);
    });
  });
}

export function getFocusPoint(event) {
  const { pageX = 0, pageY = 0 } = event.type.includes('touch') ? event.touches[0] || event.changedTouches[0] : event;
  return {
    x: pageX,
    y: pageY,
  };
}

export function convertSimpleYamlMapToJson(yaml) {
  return yaml
    .match(/\{(.*)\}/i)[1]
    .split(', ')
    .reduce(
      (acc, current) => ({
        ...acc,
        [current.split('=')[0]]: current.split('=')[1],
      }),
      {}
    );
}

export function convertSimpleYamlListMapToJson(yaml) {
  return yaml
    .replace(/\[(.*)\]/g, '$1')
    .replace(/=/g, ':')
    .replace(/(\w+)/g, '"$1"')
    .replace(/, {/g, '__{')
    .split('__')
    .map(network => JSON.parse(network))
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});
}

export function convertSimpleYamlListToArray(yaml) {
  return yaml.replace(/\[(.*)\]/g, '$1').split(', ');
}

export function getMeta(metaName) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
}

export const ajaxJsonRequest = async (method, url, data) =>
  ajaxRequest(method, url, data, null, false, {
    'Content-type': 'application/json',
    charset: 'UTF-8',
  });

const paymentMethodsExceptPaypalExpressThatHasTheSameIconAsPaypal = ({ paymentMethod }) =>
  paymentMethod !== PAYMENT_METHOD.PAYPAL_EXPRESS;

const googlePayMethodLast = (a, b) => {
  if (a.paymentMethod === PAYMENT_METHOD.GOOGLE_PAY) {
    return 1;
  }
  if (b.paymentMethod === PAYMENT_METHOD.GOOGLE_PAY) {
    return -1;
  }
  return 0;
};

const applePayMethodLast = (a, b) => {
  if (a.paymentMethod === PAYMENT_METHOD.APPLE_PAY) {
    return 1;
  }
  if (b.paymentMethod === PAYMENT_METHOD.APPLE_PAY) {
    return -1;
  }
  return 0;
};

export const getPaymentIcons = paymentTypes => {
  const iconsOfAllPaymentMethodsExceptCards = paymentTypes
    .filter(({ paymentMethod }) => paymentMethod !== PAYMENT_METHOD.CARD)
    .filter(paymentMethodsExceptPaypalExpressThatHasTheSameIconAsPaypal)
    .sort(applePayMethodLast)
    .sort(googlePayMethodLast)
    .map(({ paymentMethod }) => paymentMethod.toLowerCase());

  const cardsIcons = getCardIconsFromPaymentMethods(paymentTypes);

  return cardsIcons.concat(iconsOfAllPaymentMethodsExceptCards);
};

export const setRedirectedWithMessageFlag = () => {
  saveStorageData(REDIRECTED_WITH_NOTIFICATION, true, SESSION_STORAGE);
};

export function isRedirectedWithMessageFlagSet() {
  return !!popStorageData(REDIRECTED_WITH_NOTIFICATION, SESSION_STORAGE);
}

export function setCartValidationFlag() {
  saveStorageData(CART_VALIDATION_FLAG, true, SESSION_STORAGE);
}
