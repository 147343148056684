import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ buttonClassName, buttonOnClick, buttonText, isDisabled }) => (
  <button
    disabled={isDisabled}
    type="submit"
    className={`button ${buttonClassName || ''} ${isDisabled ? 'disabled' : ''}`}
    onClick={buttonOnClick}
  >
    <span>{buttonText}</span>
  </button>
);

Button.defaultProps = {
  buttonClassName: '',
  buttonOnClick: null,
  isDisabled: false,
};

Button.propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  buttonClassName: PropTypes.string,
  buttonOnClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default Button;
