import { convertCurrencyToNumber, decodeHtml } from 'app/utils/helpers';
import { getLoggedUserInfo, getGlobalCartInfo } from 'app/utils/globalDataHelpers';
import { getBasket, getBasketItem, isEcViewEventFired } from './qubitAnalyticsUtils';

const { locale, pageType } = window.inlineGlobalConfig;
const { language, country, currentCurrency } = locale;

if (pageType !== 'product' && !isEcViewEventFired()) {
  qubitEmitEcViewEvent();
  qubitEmitEcUserEvent();
  qubitEmitEcBasketEvents();
}

export function qubitEmitEcUserEvent() {
  getLoggedUserInfo().then(userInfo => {
    if (userInfo['userInfo/index']?.username) {
      window.uv.emit('ecUser', {
        user: {
          id: userInfo['userInfo/index'].encodedId,
          email: userInfo['userInfo/index'].username,
        },
      });
    }
  });
}

export function qubitEmitEcBasketEvents() {
  getGlobalCartInfo().then(({ entries, totalItems, subtotal, totalPrice }) => {
    if (entries.length > 0) {
      const basket = getBasket({
        subtotal: convertCurrencyToNumber(subtotal),
        subtotalWithTax: convertCurrencyToNumber(subtotal),
        total: convertCurrencyToNumber(totalPrice),
        quantity: totalItems,
      });

      entries.forEach(entry => {
        window.uv.emit('ecBasketItem', getBasketItem(basket, entry));
      });
      window.uv.emit('ecBasketSummary', { basket });
    }
  });
}

export function qubitEmitEcBasketAction(productId, sku, action) {
  window.uv.emit('ecBasketItemAction', {
    product: { productId, sku },
    action,
  });
}

export function qubitEmitEcViewEvent({ subtypes } = {}) {
  const pageTypeMapToQubit = {
    homepage: 'home',
    'product-list': 'category',
    'search-results': 'search',
    product: 'product',
    bag: 'basket',
    checkout: 'checkout',
    'order-confirmation': 'confirmation',
    'customer-support': 'help',
    'my-account': 'account',
  };
  const mappedPageType = pageTypeMapToQubit[pageType];
  window.uv.emit(
    'ecView',
    Object.assign(
      {
        type: mappedPageType || 'other',
        language: `${language.toLowerCase()}-${country.toLowerCase()}`,
        country: country.toUpperCase(),
        currency: currentCurrency,
      },
      subtypes ? { subtypes: subtypes.map(subType => decodeHtml(subType)) } : null
    )
  );
}
