import { popStorageData, saveStorageData, getStorageData } from 'app/utils/localStorage/localStorage';
import { SESSION_STORAGE } from 'app/utils/localStorage/localStorageConstants';
import {
  FLASH_MESSAGES_STORAGE,
  HIGHEST_MESSAGE_PRIORITY,
  NOTIFICATIONS_SERVICE,
  STATUS_ACTIVE,
} from './globalMessagesConstants';

export function getGlobalMessagesRoot() {
  return document.getElementById('globalMessages');
}

export function getFlashStorageRecord() {
  return popStorageData(FLASH_MESSAGES_STORAGE, SESSION_STORAGE);
}

export function getMessagePriority(descriptor) {
  return descriptor.priority || HIGHEST_MESSAGE_PRIORITY;
}

export function onServiceStart() {
  saveStorageData(NOTIFICATIONS_SERVICE, STATUS_ACTIVE, SESSION_STORAGE);
}

export function onServiceStop() {
  popStorageData(NOTIFICATIONS_SERVICE, SESSION_STORAGE);
}

export function isServiceActive() {
  return !!getStorageData(NOTIFICATIONS_SERVICE, SESSION_STORAGE);
}

export function getPageSpecificOptions(pageType) {
  const options = {};
  switch (pageType) {
    case 'product-list':
      options.headerSelector = 'header, .header__promo, .product-list__product-filter';
      options.stickyClass = 'is-sticky--plp-fixed';
      break;
    case 'checkout':
      options.headerSelector = '.checkout-wrapper > .row';
      options.stickyClass = 'is-sticky--checkout-fixed';
      break;
    default:
      options.headerSelector = '.header__promo';
  }
  return options;
}
