import { curry, forEach } from 'lodash';
import { listify } from './helpers';

export const addClass = curry((className, els) => {
  forEach(listify(els), el => {
    if (className && el.className.indexOf(className) === -1) {
      el.className += el.className ? ` ${className}` : `${className}`; // eslint-disable-line
    }
  });
  return els;
});

export const removeClass = curry((className, els) => {
  forEach(listify(els), el => {
    el.classList.remove(className);
  });
  return els;
});

export const insertContent = curry((content, el) => {
  el.appendChild(content);
  return el;
});

export const prependContent = curry((content, el) => {
  el.insertBefore(content, el.firstChild);
  return el;
});

export const removeContent = curry((content, el) => {
  el.removeChild(content);
  return el;
});

const nrOfParams = 3;
export const setAttribute = curry((attr, value, els) => {
  forEach(listify(els), el => {
    el.setAttribute(attr, value);
  });
  return els;
}, nrOfParams);

export const removeAttribute = curry((attr, els) => {
  forEach(listify(els), el => {
    el.removeAttribute(attr);
  });
  return els;
});

export const addStyle = curry((prop, value, el) => {
  const props = listify(prop);
  const values = listify(value);
  props.forEach((propName, index) => {
    el.style[propName] = values[index]; // eslint-disable-line
  });
  return el;
}, nrOfParams);

export const removeStyle = curry((prop, el) => {
  el.style.removeProperty(prop);
  return el;
});
