/* eslint-disable camelcase */
import { getLoggedUserInfo } from 'app/utils/globalDataHelpers';
import {
  getProductData,
  replaceSpecialChars,
  reduceProductFields,
  clearEcommerceAndPushToDataLayer,
  getProductEcommerceObject,
  getProductListEcommerceObject,
} from './gtmAnalyticsUtils';
import { DL_EVENT_SELECT_ITEM, DL_EVENT_VIEW_ITEM_LIST } from './gtmAnalyticsConstants';

const { locale, pageType } = window.inlineGlobalConfig;
const { categoryId, query } = window.inlinePlp || {};

export function getBasicListProps(listId, listName) {
  return { item_list_id: listId, item_list_name: listName || listId };
}

export function dlSelectItem(product, listProps) {
  clearEcommerceAndPushToDataLayer(DL_EVENT_SELECT_ITEM, getProductEcommerceObject(product, listProps));
}

export function getPLPOrSRPProps(listId, index) {
  return { index, ...getBasicListProps(listId, query || categoryId || undefined) };
}

export function dlSelectPLPOrSRPItem(product, index) {
  dlSelectItem(product, getPLPOrSRPProps(pageType, index));
}

export function dlViewItemList(products, listProps) {
  clearEcommerceAndPushToDataLayer(DL_EVENT_VIEW_ITEM_LIST, getProductListEcommerceObject(products, listProps));
}

export function dlViewPLPOrSRPItemList(products) {
  dlViewItemList(products, getPLPOrSRPProps(pageType));
}

export function dlTrackProductClick({ product, position, listName }) {
  try {
    window.dataLayer.push({
      event: 'visitor interaction',
      interaction_name: 'select product',
      page_type: replaceSpecialChars(pageType),
      ecommerce: {
        currencyCode: locale.currentCurrency,
        click: {
          products: [
            {
              ...getProductData({ ...product, productCode: product.id }),
              position,
              list: replaceSpecialChars(listName || pageType),
            },
          ],
        },
      },
    });
  } catch (error) {
    console.error(error);
  }
}

export function dlTrackProductsReceive(products, totalItems) {
  getLoggedUserInfo().then(userInfo => {
    const productsLimit = 20;
    try {
      const productsAnalytics = products.map((product, index) =>
        Object.assign({}, getProductData({ ...product, productCode: product.id }), {
          position: index,
          list: replaceSpecialChars(pageType),
        })
      );

      const searchQuery = new URL(window.location).searchParams.get('query');

      const dataLayerInfo = {
        event: 'visitor interaction',
        interaction_name: 'product impression',
        user_status: userInfo['userInfo/index']?.username ? 'logged in' : 'not logged in',
        num_products: totalItems,
        ecommerce: {
          currencyCode: locale.currentCurrency,
          impressions: reduceProductFields(productsAnalytics).slice(0, productsLimit),
        },
        ...(searchQuery ? { search_term: searchQuery } : {}),
      };

      window.dataLayer.push(dataLayerInfo);
    } catch (error) {
      console.error(error);
    }
  });
}

export function dlTrackFilterSelecting({ isSelected, facetHeaderName, facetItemId, totalItems }) {
  try {
    window.dataLayer.push({
      event: 'visitor interaction',
      interaction_name: isSelected ? 'apply filter' : 'remove filter',
      [isSelected ? 'apply_filter_category' : 'remove_filter_category']: facetHeaderName
        ? facetHeaderName.toLowerCase()
        : '',
      [isSelected ? 'apply_filter_value' : 'remove_filter_value']: facetItemId
        .replace(/C\$/g, '')
        .replace(/\$/g, '')
        .replace(/€/g, '')
        .replace(/£/g, '')
        .replace(/,/g, '.')
        .toLowerCase(),
      [isSelected ? 'apply_filter_results' : 'remove_filter_results']: totalItems,
    });
  } catch (error) {
    console.error(error);
  }
}

export function dlTrackToggleView(nrOfColumns) {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'change product list view',
    prod_list_view: nrOfColumns === 1 ? '1 column' : `${nrOfColumns} columns`,
  });
}

export function dlTrackSorting(sortName) {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'product list sort',
    prod_list_sort_by: replaceSpecialChars(sortName),
  });
}

export function dlTrackBackToTopClick() {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'back to top click',
  });
}

export function dlTrackPLPBreadcrumb(url) {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'breadcrumb click',
    breadcrumb_url: url,
  });
}
