export const DL_USER_LOGGED_STATUS = 'DL_USER_LOGGED_STATUS';
export const DL_USER_BAG_ID = 'DL_USER_BAG_ID';
export const DL_CHECKOUT_TYPE_WEBSITE = 'website';
export const DL_CHECKOUT_TYPE_PAYPAL_EXPRESS = 'express - paypal';
export const DL_CHECKOUT_TYPE_APPLE_PAY = 'express - apple pay';
export const DL_CHECKOUT_TYPE_GOOGLE_PAY = 'express - google pay';

export const DL_STEP_DELIVERY = 'delivery';
export const DL_STEP_BILLING = 'billing';
export const DL_STEP_REVIEW = 'review';

export const DL_EVENT_VIEW_PROMOTION = 'view_promotion';
export const DL_EVENT_SELECT_PROMOTION = 'select_promotion';
export const DL_EVENT_USE_VALID_COUPON = 'use_valid_coupon';
export const DL_EVENT_USE_INVALID_COUPON = 'use_invalid_coupon';
export const DL_EVENT_ADD_TO_WISHLIST = 'add_to_wishlist';
export const DL_EVENT_VIEW_ITEM_LIST = 'view_item_list';
export const DL_EVENT_VIEW_ITEM = 'view_item';
export const DL_EVENT_SELECT_ITEM = 'select_item';
export const DL_EVENT_ADD_TO_CART = 'add_to_cart';
export const DL_EVENT_VIEW_CART = 'view_cart';
export const DL_EVENT_REMOVE_FROM_CART = 'remove_from_cart';
export const DL_EVENT_BEGIN_CHECKOUT = 'begin_checkout';
export const DL_EVENT_CHECKOUT_PROGRESS = 'checkout_progress';
export const DL_EVENT_ADD_SHIPPING_INFO = 'add_shipping_info';
export const DL_EVENT_ADD_PAYMENT_INFO = 'add_payment_info';
export const DL_EVENT_PURCHASE = 'purchase';

export const DL_ITEM_LIST_YMAL = 'you may also like';
